export class Installation {
  id: string;
  orderNumber: string;
  rfidTagId: string;
  conveyorId: string;
  organizationId: string;
  installedUtc: string;
  deinstalledUtc: string;
  width: string;
  length: string;
  conveyorName: string;
  machineId: string;
  machineName: string;

  static fromJson(json: any): Installation {
    let installation = new Installation();
    installation.id = json.id;
    installation.orderNumber = json.orderNumber;
    installation.rfidTagId = json.rfidTagId;
    installation.conveyorId = json.conveyorId;
    installation.organizationId = json.organizationId;
    installation.installedUtc = json.installedUtc;
    installation.deinstalledUtc = json.deinstalledUtc;
    installation.width = json.width;
    installation.length = json.length;
    installation.conveyorName = json.conveyorName;
    installation.machineId = json.machineId;
    installation.machineName = json.machineName;
    return installation;
  }
}
