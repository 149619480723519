import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Action, ActionCreator, Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { Subscription } from 'rxjs';

export interface Model {
  id?: string;
  created?: Date;
  updated?: Date;
  deleted?: boolean;
  dirty: boolean;
}

interface Err {
  error: Error;
}

@Injectable({
  providedIn: 'root',
})
export class StateUtils {
  constructor(private store: Store, private actions: Actions) {}

  public actionsToPromise<T>(
    action: Action,
    successAction: ActionCreator<any, (props: T) => T & TypedAction<any>>,
    failedAction: ActionCreator<any, (props: Err) => Err & TypedAction<any>>
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      const subs: Subscription[] = [];

      const successSub = this.actions.pipe(ofType(successAction)).subscribe((result: T) => {
        for (const sub of subs) sub.unsubscribe();
        resolve(result);
      });

      const failedSub = this.actions.pipe(ofType(failedAction)).subscribe((err) => {
        for (const sub of subs) sub.unsubscribe();
        reject(err.error);
      });

      subs.push(successSub);
      subs.push(failedSub);
      this.store.dispatch(action);
    });
  }

  static combineStateArr<T extends Model>(stateItems: T[], incomingItems: T[]): T[] {
    if (!incomingItems?.length) return [...stateItems];

    let result: T[] = [];
    let incomingItemMap: { [id: string]: T } = {};
    let stateItemMap: { [id: string]: boolean } = {};

    for (const item of incomingItems) {
      if (!item) continue;
      incomingItemMap[item.id] = item;
    }

    for (let stateItem of stateItems) {
      const incomingItem = incomingItemMap[stateItem.id];
      if (incomingItem) {
        result.push(this.selectItemUsingMergeLogic(stateItem, incomingItem));
      } else {
        result.push(stateItem);
      }
      stateItemMap[stateItem.id] = true;
    }

    for (const item of incomingItems) {
      if (!stateItemMap[item.id]) result.push(item);
    }

    return result;
  }

  static selectItemUsingMergeLogic<T extends Model>(stateItem: T, incomingItem: T): T {
    if (incomingItem.dirty) return incomingItem;

    if (!incomingItem.dirty && stateItem.dirty) {
      const incomingDate = new Date(incomingItem.updated);
      const stateDate = new Date(stateItem.updated);
      stateDate.setMilliseconds(0);
      incomingDate.setMilliseconds(0);
      if (incomingDate >= stateDate) return incomingItem;
      return stateItem;
    }

    return incomingItem;
  }

  static combineState<T extends Model>(existingItems: T[], newItem: T): T[] {
    if (!newItem) return [...existingItems];

    existingItems = [...existingItems];
    if (existingItems.find((x) => x.id === newItem.id)) {
      existingItems = existingItems.map((x) => (x.id === newItem.id ? newItem : x));
    } else {
      existingItems.push(newItem);
    }

    return existingItems;
  }
}
