import { FormsModule } from '@angular/forms';

import { NgModule, isDevMode } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';

import { AppstraxServices } from '@appstrax/services';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MsalGuard, MsalService } from '@azure/msal-angular';
import { MsalModule, MsalBroadcastService } from '@azure/msal-angular';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';

import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { SurveyModule } from './surveys/survey.module';
import { InstallationModule } from './installation/installation.module';

import { environment } from './../environments/environment';

import { UtilService } from './utils/util.service';
import { ApiService } from './services/api/api.service';
import { AuthService } from './services/auth/auth.service';
import { SyncService } from './services/sync/sync.service';
import { ModalService } from './services/modal/modal.service';
import { StorageService } from './services/storage/storage.service';
import { OnlineService } from './services/online-service/online.service';
import { FetchService } from './services/sync/fetch.service';

import { CscEffects } from './state/csc/csc.effects';
import { BeltEffects } from './state/belt/belt.effects';
import { UserEffects } from './state/user/user.effects';

import { metaReducers, reducers } from './state/core.reducer';
import { SurveyEffects } from './state/survey/survey.effects';
import { CustomerEffects } from './state/customer/customer.effects';
import { InstallationEffects } from './state/installation/installation.effects';

import AuthMsalConfig from './auth-config';
import { CBeltAuthGuard } from './guards/c-belt-auth.guard';
import { AuthHttpInterceptor } from './utils/http-interceptor';

import { AppComponent } from './app.component';
import { NavComponent } from './components/sidebar/nav/nav.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { AppLayoutComponent } from './components/app-layout/app-layout.component';
import { TranslationsComponent } from './pages/translations/translations.component';
import { LanguageComponent } from './pages/language/language.component';
import { DeleteModalComponent } from './modals/delete-modal/delete-modal.component';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { InfoModalComponent } from './modals/info-modal/info-modal.component';
import { LogoutComponent } from './pages/logout/logout.component';

AppstraxServices.init({ storageUrl: environment.storageApiBaseUrl })
  .then(() => console.log('Services initialized'))
  .catch((err) => console.log(err));

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    SidebarComponent,
    AppLayoutComponent,
    TranslationsComponent,
    LanguageComponent,
    ConfirmModalComponent,
    InfoModalComponent,
    DeleteModalComponent,
    LogoutComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    TooltipModule,
    FormsModule,
    ReactiveFormsModule,

    ServiceWorkerModule.register('/ngsw-worker.js', {
      enabled: environment.staging || environment.production,
    }),
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument({ maxAge: 25 }),
    EffectsModule.forRoot([
      CscEffects,
      BeltEffects,
      UserEffects,
      SurveyEffects,
      CustomerEffects,
      InstallationEffects,
    ]),
    MsalModule.forRoot(
      new PublicClientApplication(AuthMsalConfig.fetchDesktopConfig()),
      { interactionType: InteractionType.Redirect },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          [`${environment.baseUrl}/*`, [environment.ammscanApiScope]],
          [`${environment.cBeltApi}/*`, null],
        ]),
      },
    ),
    SharedModule,
    SurveyModule,
    InstallationModule,
    AppRoutingModule,
  ],
  providers: [
    ApiService,
    AuthService,
    ModalService,
    OnlineService,
    SyncService,
    FetchService,
    StorageService,
    UtilService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    MsalService,
    MsalGuard,
    CBeltAuthGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
