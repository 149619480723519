import { InstallationState } from './installation.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectInstallation = createFeatureSelector<InstallationState>('installation');

export const current = createSelector(
  selectInstallation,
  (state: InstallationState) => state.current
);

export const selectCurrentSummary = () => createSelector(current, (state) => state);

export const selectCurrentCompany = () => createSelector(current, (state) => state.company);

export const selectCurrentGatewayDevices = () => createSelector(current, (state) => state.gateways);

export const selectCurrentConveyors = () => createSelector(current, (state) => state.conveyors);

export const selectSensorToEdit = () => createSelector(current, (state) => state.sensor);

export const selectCurrentBelt = () => createSelector(current, (state) => state.belt);

export const selectCurrentConveyor = () => createSelector(current, (state) => state.conveyor);

export const selectCurrentGateway = () => createSelector(current, (state) => state.gateway);

export const selectCurrentConveyorReader = () =>
  createSelector(current, (state) => state.conveyorReader);

export const selectCurrentMachine = () => createSelector(current, (state) => state.machine);
