import { BsModalRef } from 'ngx-bootstrap/modal';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { v4 as uuidv4 } from 'uuid';

import { AuthService } from '../../../../services/auth/auth.service';
import { FileService } from './../../../../services/file/file.service';
import { MappingService } from './../../../../services/mapping/mapping.service';
import { ModalService } from './../../../../services/modal/modal.service';
import { SyncService } from './../../../../services/sync/sync.service';
import { TranslateService } from './../../../../services/translate.service';

import { MenuItem } from './../../../../shared/components/meta-menu/meta-menu.component';
import { Belt } from './../../../../models/belt.model';
import { Survey } from './../../../../models/survey.model';
import { Table } from './../../../../shared/components/table/table.component';

import * as BeltActions from '../../../../state/belt/belt.actions';

@Component({
  selector: 'app-desktop-belt-list',
  templateUrl: './desktop-belt-list.component.html',
  styleUrl: './desktop-belt-list.component.scss',
})
export class DesktopBeltListComponent implements OnInit, OnChanges {
  @ViewChild('imagePreviewModal') imagePreviewModal;
  @ViewChild('importModal') importModal;

  @Input() survey: Survey;
  @Input() filterCount: number;
  @Input() hasActiveFilter: boolean;
  @Input() belts: Belt[] = [];

  @Input() searchText: string;
  @Output() searchTextChange: EventEmitter<string> = new EventEmitter<string>();

  @Output() itemClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() copyClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeSurveyClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadSurveyCsvClick: EventEmitter<any> = new EventEmitter<any>();

  public beltTable: Table;
  public currentBelt: Belt;
  public fileDetails: any;
  public menuItems: MenuItem[] = [];
  private bsModalRef: BsModalRef;

  constructor(
    private authService: AuthService,
    private fileService: FileService,
    private map: MappingService,
    private modalService: ModalService,
    private store: Store,
    private syncService: SyncService,
    public t: TranslateService
  ) {}

  public ngOnInit() {
    this.createMenuItems();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.belts) {
      this.beltTable = this.map.beltsToTable(
        this.belts,
        (belt) => this.onCopyClick(belt),
        (belt) => this.onRemoveBeltClick(belt)
      );
    }
  }

  private openImageModal(modal, belt: Belt) {
    this.currentBelt = belt;
    this.bsModalRef = this.modalService.show(modal, { class: 'modal-md' });
  }

  public closeModal() {
    this.bsModalRef.hide();
  }

  public onCopyClick(belt: Belt) {
    this.copyClick.emit(belt);
  }

  public onRemoveBeltClick(belt: Belt) {
    this.removeClick.emit(belt);
  }

  public onRemoveSurveyClick(survey: Survey) {
    this.removeSurveyClick.emit(survey);
  }

  public onItemClick(rowItem) {
    const classList: string = rowItem.event?.target.className;
    const parentClassList: string = rowItem.event?.target.parentNode.className;

    if (classList.includes('photo-btn') || parentClassList.includes('photo-btn')) {
      const belt = this.belts.find((belt) => belt.id == rowItem.id);
      this.openImageModal(this.imagePreviewModal, belt);
      return;
    }

    this.itemClick.emit(rowItem);
  }

  public onFilterButtonClick() {
    this.filterButtonClick.emit();
  }

  public onDownloadSurveyCsvClick() {
    this.downloadSurveyCsvClick.emit();
  }

  public onSearchTextChange() {
    this.searchTextChange.emit(this.searchText);
  }

  private createMenuItems() {
    this.menuItems = [
      {
        title: `${this.t.translate('_EXPORT_CSV')}`,
        symbol: 'download',
        onClick: () => {
          this.onDownloadSurveyCsvClick();
        },
      },
      {
        title: `${this.t.translate('_IMPORT_CSV')}`,
        symbol: 'upload',
        onClick: () => {
          this.fileDetails = {};
          this.bsModalRef = this.modalService.show(this.importModal, { class: 'modal-md' });
        },
      },
    ];
  }

  public async csvFileSelected(fileUploadElement) {
    this.fileDetails = {};

    try {
      const file: File = fileUploadElement.target.files[0];
      const csvBeltConfigs: any[] = await this.fileService.readCsvFile(file);
      const validBeltConfigs = csvBeltConfigs.filter((beltConfig) =>
        this.validateBeltConfig(beltConfig, csvBeltConfigs.indexOf(beltConfig))
      );

      this.fileDetails = {
        ...this.fileDetails,
        file,
        csvBeltConfigs,
        validBeltConfigs,
      };
    } catch (err) {
      console.log(err);
    }
  }

  public async importSurveyCsv() {
    try {
      const userEmail = this.authService.getUser().email;

      const beltConfigs: Belt[] = this.fileDetails.validBeltConfigs.map((obj) => {
        obj['quantity'] = obj['quantity'].toString().replaceAll(' ', '');
        obj['length_(mm)'] = obj['length_(mm)'].toString().replaceAll(' ', '');
        obj['width_(mm)'] = obj['width_(mm)'].toString().replaceAll(' ', '');

        const beltConfig: Belt = new Belt(this.survey.id);
        beltConfig.id = uuidv4();
        beltConfig.created = new Date();
        beltConfig.productionLine = obj['production_line'];
        beltConfig.productReference = obj['product_reference_(cpr)'];
        beltConfig.wtl = obj['wtl'];
        beltConfig.synOrMod = obj['synthetic_or_modular'].toLowerCase();
        beltConfig.quantity = parseInt(obj['quantity'] || 0);
        beltConfig.length = parseInt(obj['length_(mm)'] || 0);
        beltConfig.width = parseInt(obj['width_(mm)'] || 0);
        beltConfig.carriersBordoflex = obj['carriers/bordoflex'];
        beltConfig.beltAccess = obj['belt_access_environment'];
        beltConfig.itemReference = obj['item_reference'];
        beltConfig.comments = obj['comments'];
        beltConfig.surveyId = this.survey.id;
        beltConfig.surveyor = userEmail;
        beltConfig.dirty = true;

        if (beltConfig.synOrMod == 'synthetic') {
          beltConfig.weldType = obj['weld_type'];
          beltConfig.weldDirection = obj['weld_direction'];
          beltConfig.trackingGuide = obj['tracking_guide'];
          beltConfig.joint = obj['joint'];
          beltConfig.material = obj['material'];
        }

        if (beltConfig.synOrMod == 'modular') {
          beltConfig.sprocketBore = obj['sprocket_bore'];
          beltConfig.sprocketPd = obj['sprocket_pd'];
          beltConfig.pinMaterial = obj['pin_material'];
          beltConfig.beltColour = obj['belt_colour'];
          beltConfig.beltMaterial = obj['belt_material'];
          beltConfig.beltType = obj['belt_surface'];
        }

        return beltConfig;
      });

      this.store.dispatch(BeltActions.addBeltsSuccess({ belts: beltConfigs }));
      await this.syncService.trySync();
      this.bsModalRef.hide();
      this.fileDetails = {};
    } catch (err) {
      console.log(err);
    }
  }

  private validateBeltConfig(beltConfigInput, beltIndex): boolean {
    const invalidFields = [];

    beltConfigInput['quantity'] = beltConfigInput['quantity']?.toString().replaceAll(' ', '');
    beltConfigInput['length_(mm)'] = beltConfigInput['length_(mm)']?.toString().replaceAll(' ', '');
    beltConfigInput['width_(mm)'] = beltConfigInput['width_(mm)']?.toString().replaceAll(' ', '');

    if (
      beltConfigInput['synthetic_or_modular'] != 'Synthetic' &&
      beltConfigInput['synthetic_or_modular'] != 'Modular'
    ) {
      invalidFields.push('synthetic_or_modular');
    }
    if (
      beltConfigInput['synthetic_or_modular'] == 'Synthetic' &&
      beltConfigInput['joint'].length <= 0
    ) {
      invalidFields.push('_joint');
    }
    if (
      !beltConfigInput['width_(mm)'] ||
      beltConfigInput['width_(mm)'] < 0 ||
      isNaN(beltConfigInput['width_(mm)'])
    ) {
      invalidFields.push('width');
    }
    if (
      !beltConfigInput['length_(mm)'] ||
      beltConfigInput['length_(mm)'] < 0 ||
      isNaN(beltConfigInput['length_(mm)'])
    ) {
      invalidFields.push('length');
    }
    if (
      !beltConfigInput['quantity'] ||
      beltConfigInput['quantity'] < 1 ||
      isNaN(beltConfigInput['quantity'])
    ) {
      invalidFields.push('quantity');
    }
    if (
      !beltConfigInput['product_reference_(cpr)']?.length ||
      (beltConfigInput['product_reference_(cpr)'].length &&
        beltConfigInput['product_reference_(cpr)'].length > 250)
    ) {
      invalidFields.push('productReference');
    }
    if (beltConfigInput['comments'] && beltConfigInput['comments'].length > 250) {
      invalidFields.push('comments');
    }
    if (beltConfigInput['production_line'] && beltConfigInput['production_line'].length > 50) {
      invalidFields.push('production_line');
    }

    if (beltConfigInput['belt_access'] && beltConfigInput['belt_access'].length > 250) {
      invalidFields.push('belt_access');
    }
    if (beltConfigInput['tracking_guide'] && beltConfigInput['tracking_guide'].length > 250) {
      invalidFields.push('tracking_guide');
      return;
    }
    if (beltConfigInput['carriersBordoflex'] && beltConfigInput['carriersBordoflex'].length > 250) {
      invalidFields.push('carriersBordoflex');
      return;
    }

    if (beltConfigInput['synthetic_or_modular']) {
      if (beltConfigInput['synthetic_or_modular'].toLowerCase() === 'synthetic') {
        if (beltConfigInput['material'] && beltConfigInput['material'].length > 250) {
          invalidFields.push('width');
        }
      }

      if (beltConfigInput['synthetic_or_modular'].toLowerCase() === 'modular') {
        this.validateModularBeltConfig(invalidFields, beltConfigInput);
      }
    }

    if (invalidFields.length) {
      // NOTE: add 3 rows (index + 1, header and description rows)
      console.log(`Row ${beltIndex + 3} not imported`, invalidFields);

      if (!this.fileDetails?.invalidBeltConfigs) {
        this.fileDetails = {
          invalidBeltConfigs: [],
        };
      }

      this.fileDetails.invalidBeltConfigs.push({
        row: beltIndex + 3,
        invalidFields,
      });

      return;
    }
    return beltConfigInput;
  }

  private validateModularBeltConfig(invalidFields, beltConfigInput) {
    if (beltConfigInput['belt_colour'] && beltConfigInput['belt_colour'].length > 250) {
      invalidFields.push('color');
      return;
    }
    if (beltConfigInput['belt_surface'] && beltConfigInput['belt_surface'].length > 250) {
      invalidFields.push('surface');
      return;
    }
    if (beltConfigInput['belt_material'] && beltConfigInput['belt_material'].length > 250) {
      invalidFields.push('material');
      return;
    }
    if (beltConfigInput['pin_material'] && beltConfigInput['pin_material'].length > 250) {
      invalidFields.push('pin material');
      return;
    }
    return beltConfigInput;
  }

  public getFileValidationColour(): 'warning' | 'danger' | 'success' {
    if (!this.fileDetails?.file) {
      return;
    }
    if (this.fileDetails?.invalidBeltConfigs?.length && this.fileDetails.validBeltConfigs.length) {
      return 'warning';
    }
    if (!this.fileDetails.invalidBeltConfigs?.length && !this.fileDetails.validBeltConfigs.length) {
      return 'danger';
    }
    if (!this.fileDetails.invalidBeltConfigs?.length && this.fileDetails.validBeltConfigs.length) {
      return 'success';
    }
  }

  public downloadCsvTemplate() {
    const downloadLink = document.createElement('a');

    downloadLink.href = '/assets/files/AMMscan CSV Import Template.csv';
    downloadLink.target = '_blank';
    downloadLink.setAttribute('data-testid', 'download-survey-csv-template');
    downloadLink.setAttribute('download', 'AMMscan CSV Import Template.csv');

    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  public translateCSVDialog() {
    if (
      !this.fileDetails.invalidBeltConfigs?.length &&
      !this.fileDetails.validBeltConfigs?.length
    ) {
      return this.t.translate('_NO_ROWS_IMPORTED_RECHECK_YOUR_DATA');
    }

    if (!this.fileDetails.invalidBeltConfigs?.length && this.fileDetails.validBeltConfigs?.length) {
      return `${this.t.translate('_LOOKS_GOOD')} ${this.t.translate('_ROWS_WILL_BE_IMPORTED')}`;
    }
  }

  public translateInvalidBeltConfigs(invalidBelt: any) {
    return `${this.t.translate('_ROW')} ${invalidBelt.row} ${this.t.translate(
      '_INVALID'
    )}. ${this.t.translate('_FIX')}: ${invalidBelt.invalidFields?.toString()}`;
  }
}
