import { BaseState } from '../base.state';
import { Belt } from '../../models/belt.model';

export class BeltState extends BaseState {
  belts: Belt[];
  syntheticMaterials: any[];
  modularMaterials: any[];
  modularTypes: any[];
  modularColours: any[];
}

export const initialState: BeltState = {
  belts: [],
  syntheticMaterials: [],
  modularMaterials: [],
  modularTypes: [],
  modularColours: [],
};
