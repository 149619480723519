import { Injectable } from '@angular/core';

import { PairedSensor } from '../../models/paired-sensor.model';
import { Sensor, UpdateSensorPayload } from '../../models/sensor.model';

import { CBeltApiService } from '../api/cBeltApi.service';

@Injectable({ providedIn: 'root' })
export class SensorsService {
  constructor(private cBeltApiService: CBeltApiService) {}

  async getSensors(conveyorId: string): Promise<Sensor[]> {
    const options = { params: { conveyorId: conveyorId } };
    const response = await this.cBeltApiService.get('/sensors', options);
    return response['result']['items'].map((x) => Sensor.fromJson(x));
  }

  async getPairedSensors(): Promise<PairedSensor[]> {
    const options = { params: { pageIndex: 1, pageSize: 100 } };
    const response = await this.cBeltApiService.get('/pairedsensors', options);
    return response['result']['items'].map((x) => PairedSensor.fromJson(x));
  }

  async getSensorsByEdid(edid: string): Promise<Sensor[]> {
    const options = { params: { edid: edid } };
    const response = await this.cBeltApiService.get('/sensors', options);
    return response['result']['items'].map((x) => Sensor.fromJson(x));
  }

  async getGatewayIdBySensorId(sensorId: string): Promise<string> {
    const response = await this.cBeltApiService.get(`/sensors/${sensorId}/gateways`);
    return response['result']['items'][0].gatewayId;
  }

  async updateSensor(sensor: UpdateSensorPayload, id: string): Promise<void> {
    return await this.cBeltApiService.put(`/sensors/${id}`, sensor);
  }

  async mapSensorAndGateway(sensorId: string, gatewayDeviceId: string): Promise<void> {
    const body = { gatewayIotHubDeviceId: gatewayDeviceId };
    return await this.cBeltApiService.post(`/sensors/${sensorId}/gateways`, body);
  }

  async unMapSensorAndGateway(sensorId: string, gatewayDeviceId: string): Promise<void> {
    return await this.cBeltApiService.delete(`/sensors/${sensorId}/gateways/${gatewayDeviceId}`);
  }
}
