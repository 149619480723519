import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { CBelt } from 'src/app/models/cBelt.model';
import { Installation } from 'src/app/models/installation.model';

import { ApiService } from '../api/api.service';
import { CBeltApiService } from '../api/cBeltApi.service';

@Injectable({ providedIn: 'root' })
export class BeltsService {
  constructor(private apiService: ApiService, private cBeltApiService: CBeltApiService) {}

  async getAllBelts(): Promise<CBelt[]> {
    const options = { params: { pageSize: 1000 } };
    const response = await this.cBeltApiService.get('/belts', options);
    return response['result']['items'].map((x) => CBelt.fromJson(x));
  }

  async getAllInstallations(): Promise<Installation[]> {
    const response = await this.cBeltApiService.get('/belts');
    return response['result']['items'].map((x) => Installation.fromJson(x));
  }

  async getBelts(tagId: string): Promise<CBelt[]> {
    const options = { params: { rfidTagId: tagId, unpaired: true } };
    const response = await this.cBeltApiService.get('/belts', options);
    return response['result']['items'].map((x) => CBelt.fromJson(x));
  }

  async addBelt(belt: CBelt): Promise<any> {
    return await this.cBeltApiService.post('/belts', belt);
  }

  async updateBelt(belt: CBelt): Promise<any> {
    return await this.cBeltApiService.put(`/belts/${belt.id}`, belt);
  }

  async updateBeltWithConveyorId(belt: CBelt): Promise<any> {
    const body = { conveyorId: belt.conveyorId };
    return await this.cBeltApiService.put(`/belts/${belt.id}/conveyors`, body);
  }

  async deleteBelt(belt: CBelt): Promise<any> {
    return await this.cBeltApiService.delete(`/belts/${belt.id}`);
  }

  async getSalesLocationCode(orderNumber: String): Promise<any> {
    const ammcareBaseUrl = environment.ammcareBaseUrl;
    return await this.apiService.get(ammcareBaseUrl + `/machines/item-details/${orderNumber}`, {});
  }
}
