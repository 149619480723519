import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';

@Injectable()
export class CBeltAuthGuard {
  constructor(private router: Router, private authService: AuthService) {}

  async canActivate(_route: ActivatedRouteSnapshot): Promise<boolean> {
    return !!this.authService.getUser();
  }

  async canActivateChild(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.canActivate(route);
  }
}
