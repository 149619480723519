<app-desktop-header [header]="t.translate('_ADD_NEW_CUSTOMER')">
  <div content>
    <button
      class="btn btn-primary"
      (click)="onSave()">
      {{ t.translate("_SAVE") }}
    </button>
  </div>
</app-desktop-header>

<div class="mx-0 mx-lg-5 mt-lg-8">
  <app-mobile-header
    previousPageTitle="Surveys"
    [routerLinkUrl]="['/surveys']">
    <div content>
      <button
        class="btn btn-primary"
        (click)="onSave()">
        {{ t.translate("_SAVE") }}
      </button>
    </div>
  </app-mobile-header>

  <div class="bg-white rounded mb-4 mt-md-4 p-md-3">
    <div class="mobile">
      <div class="d-flex align-items-center justify-content-between px-3 py-2 border-bottom mb-3">
        <h1 class="header my-2">{{ t.translate("_CUSTOMER_DETAILS") }}</h1>
      </div>
    </div>

    <div class="d-flex justify-content-between gap-4 p-3">
      <div class="flex-grow-1 customer-inputs">
        <div class="mb-4">
          <app-input
            [(model)]="customer.customer_name"
            [form]="form"
            name="name"
            [label]="t.translate('_COMPANY_NAME')">
          </app-input>
        </div>

        <div class="position-relative">
          <label class="form-label">
            {{ t.translate("_CSC") }} <span class="text-danger small ng-star-inserted">*</span>
          </label>
          <ng-select
            data-testid="csc-dropdown"
            [items]="cscs$ | async"
            [editableSearchTerm]="true"
            [(ngModel)]="customer.opco_id"
            [class.border-danger]="form.submitted && !customer.opco_id"
            appendTo="body"
            bindLabel="title"
            bindValue="id"
            dropdownPosition="bottom"
            name="cscOption">
          </ng-select>
          <div
            *ngIf="form.submitted && form.invalid && !customer.opco_id"
            class="errors">
            <div class="error">{{ t.translate("_CSC") }}</div>
          </div>
        </div>
      </div>

      <div class="col-4 desktop">
        <div class="position-relative">
          <image-input
            [label]="t.translate('_CUSTOMER_IMAGE')"
            [size]="185"
            [(image)]="image">
          </image-input>
        </div>
      </div>
    </div>
  </div>
</div>
