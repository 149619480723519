export class ConveyorReader {
  id: string;

  machineName: string;
  machineNickName: string;
  conveyorName: string;
  description: string;
  diameter: Number;
  idleDrumDiameter: Number;
  customerProductReference: string;
  readerId: string;

  static fromJson(json: any): ConveyorReader {
    let reader = new ConveyorReader();
    reader.id = json.id;
    reader.machineName = json.machineName;
    reader.machineNickName = json.machineNickName;
    reader.conveyorName = json.conveyorName;
    reader.description = json.description;
    reader.diameter = json.diameter;
    reader.idleDrumDiameter = json.idleDrumDiameter;
    reader.customerProductReference = json.customerProductReference;
    reader.readerId = json.readerId;
    return reader;
  }
}
