import { Injectable } from '@angular/core';

declare var aesjs;

@Injectable({ providedIn: 'root' })
export class EncryptionService {
  private key = [
    43, 126, 21, 22, 40, 174, 210, 166, 171, 247, 21, 136, 9, 207, 79, 60,
  ];
  private iv = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

  encryptValue(bytes: Uint8Array): Uint8Array {
    const aesCbc = new aesjs.ModeOfOperation.cbc(this.key, this.iv);
    return aesCbc.encrypt(bytes);
  }

  decryptValue(bytes: Uint8Array): Uint8Array {
    const aesCbc = new aesjs.ModeOfOperation.cbc(this.key, this.iv);
    return aesCbc.decrypt(bytes);
  }
}
