import { createAction, props } from '@ngrx/store';

import { User } from 'src/app/services/auth/auth.service';

export const setError = createAction(
  '[User] Set Error',
  props<{ error: any }>()
);

// export const login = createAction('[User] Login Initiated', props<LoginDto>());
export const loginSuccess = createAction(
  '[User] Login Success',
  props<{ user: User }>()
);
export const loginFailed = createAction(
  '[User] Login Failed',
  props<{ error: any }>()
);

export const logout = createAction('[User] Logout Initiated');
export const logoutSuccess = createAction('[User] Logout Success');

// export const register = createAction(
//   '[User] Register Initiated',
//   props<RegisterDto>()
// );
// export const registerSuccess = createAction(
//   '[User] Register Success',
//   props<{ user: User }>()
// );
// export const registerFailed = createAction(
//   '[User] Register Failed',
//   props<{ error: any }>()
// );

export const subscribe = createAction(
  '[User] Subscribe to Newsletter',
  props<{ subscribed: boolean }>()
);
export const subscribeSuccess = createAction(
  '[User] Subscribe to Newsletter Success',
  props<{ subscribed: boolean }>()
);
export const subscribeFailed = createAction(
  '[User] Subscribe to Newsletter Failed',
  props<{ error: any }>()
);

// export const forgotPassword = createAction(
//   '[User] Forgot Password Initiated',
//   props<ForgotPasswordDto>()
// );
// export const forgotPasswordSuccess = createAction(
//   '[User] Forgot Password Success'
// );
// export const forgotPasswordFailed = createAction(
//   '[User] Forgot Password Failed',
//   props<{ error: any }>()
// );

// export const resetPassword = createAction(
//   '[User] Reset Password Initiated',
//   props<ResetPasswordDto>()
// );
// export const resetPasswordSuccess = createAction(
//   '[User] Reset Password Success'
// );
// export const resetPasswordFailed = createAction(
//   '[User] Reset Password Failed',
//   props<{ error: any }>()
// );
