import { Component, Input } from '@angular/core';
import { Customer } from 'src/app/models/customer.model';

@Component({
  selector: 'app-company-validation',
  templateUrl: './company-validation.component.html',
  styleUrl: './company-validation.component.scss',
})
export class CompanyValidationComponent {
  @Input() customer: Customer;

  public complete(): boolean {
    return (
      this.customer.customer_name !== null &&
      this.customer.customerNumber !== null
    );
  }

  public incomplete(): boolean {
    return (
      this.customer.customer_name === null ||
      this.customer.customerNumber === null
    );
  }
}
