export class Sensor {
  id: string;

  conveyorId: string;
  uniqueId: Number;
  sensorNickname: string;
  aliasName: string;
  sensorType: string;
  gatewayDeviceId: string;
  description: string;
  sid: string;
  edid: Number;
  role: string;

  static fromJson(json: any): Sensor {
    let sensor = new Sensor();
    sensor.id = json.id;
    sensor.conveyorId = json.conveyorId;
    sensor.sensorNickname = json.aliasName;
    sensor.description = json.description;
    sensor.sid = json.sid;
    sensor.edid = json.edid;
    sensor.role = json.role;
    return sensor;
  }
}

export interface UpdateSensorPayload {
  conveyorId: string;
  aliasName: string;
  description: string;
}
