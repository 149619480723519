import { Component, Input } from '@angular/core';
import { Gateway } from 'src/app/models/gateway.model';

@Component({
  selector: 'app-gateway-validation',
  templateUrl: './gateway-validation.component.html',
  styleUrl: './gateway-validation.component.scss',
})
export class GatewayValidationComponent {
  @Input() gateway: Gateway;

  public complete(): boolean {
    switch (this.gateway.wifiType) {
      case '4g Device':
        return true;
      case 'Static':
        return (
          this.gateway.gatewayDeviceId !== null &&
          this.gateway.devicePassword !== null &&
          this.gateway.networkName !== null &&
          this.gateway.ipAddress !== null &&
          this.gateway.networkMask !== null &&
          this.gateway.gatewayAddress !== null &&
          this.gateway.dns !== null
        );
      case 'DHCP':
        return (
          this.gateway.gatewayDeviceId !== null &&
          this.gateway.devicePassword !== null &&
          this.gateway.networkName !== null
        );
    }
  }

  public incomplete(): boolean {
    switch (this.gateway.wifiType) {
      case '4g Device':
        return false;
      case 'Static':
        return (
          this.gateway.gatewayDeviceId === null ||
          this.gateway.devicePassword === null ||
          this.gateway.networkName === null ||
          this.gateway.ipAddress === null ||
          this.gateway.networkMask === null ||
          this.gateway.gatewayAddress === null ||
          this.gateway.dns === null
        );
      case 'DHCP':
        return (
          this.gateway.gatewayDeviceId === null ||
          this.gateway.devicePassword === null ||
          this.gateway.networkName === null
        );
    }
  }
}
