export class PairedSensor {
  aliasName: string;
  batteryLevel: Number;
  conveyorId: string;
  conveyorName: string;
  createdUtc: string;
  description: string;
  edid: Number;
  gatewayId: string;
  gatewayName: string;
  id: string;
  isActive: boolean;
  macAddress: string;
  machineId: string;
  machineName: string;
  role: string;
  sid: string;

  static fromJson(json: any): PairedSensor {
    let sensor = new PairedSensor();
    sensor.aliasName = json.aliasName;
    sensor.batteryLevel = json.batteryLevel;
    sensor.conveyorId = json.conveyorId;
    sensor.conveyorName = json.conveyorName;
    sensor.createdUtc = json.createdUtc;
    sensor.description = json.description;
    sensor.edid = json.edid;
    sensor.gatewayId = json.gatewayId;
    sensor.gatewayName = json.gatewayName;
    sensor.id = json.id;
    sensor.isActive = json.isActive;
    sensor.macAddress = json.macAddress;
    sensor.machineId = json.machineId;
    sensor.machineName = json.machineName;
    sensor.role = json.role;
    sensor.sid = json.sid;
    return sensor;
  }
}
