import { cloneDeep } from 'lodash';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Gateway } from 'src/app/models/gateway.model';

import { StateService } from 'src/app/services/c-belt/state.service';

import { take } from 'rxjs';
import { Store } from '@ngrx/store';
import { ConfigurationState } from 'src/app/state/installation/installation.state';
import * as InstallationActions from 'src/app/state/installation/installation.actions';
import * as InstallationSelectors from 'src/app/state/installation/installation.selector';

@Component({
  selector: 'app-gateway-configuration',
  templateUrl: './gateway-configuration.component.html',
  styleUrls: ['./gateway-configuration.component.scss'],
})
export class GatewayConfigurationComponent implements OnInit {
  editing: boolean;
  baseRoute: string;
  companyId: string;
  gatewayDevice: Gateway = null;
  gatewayDevices: Gateway[] = [];
  current: ConfigurationState = null;

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private stateService: StateService
  ) {}

  ngOnInit() {
    this.route.url.subscribe((route) => {
      this.baseRoute = route[0].path;
    });
    this.route.params.subscribe((params) => {
      this.companyId = params['company_id'];
      this.subscribeToCurrentConfiguration();
    });
  }

  subscribeToCurrentConfiguration() {
    const select = InstallationSelectors.selectCurrentSummary();
    this.store
      .select(select)
      .pipe(take(1))
      .subscribe((current) => {
        this.current = cloneDeep(current);
        this.gatewayDevice = this.current.gateway;
        this.gatewayDevices = this.current.gateways;
        this.editing = this.current.editing;
      });
  }

  navToNewDevice(editing: boolean, gateway?: Gateway) {
    this.store.dispatch(InstallationActions.setCurrentGatewayDevice({ gatewayDevice: gateway }));

    this.router.navigate([
      this.baseRoute,
      this.companyId,
      'gateway-configuration',
      'new-gateway-device',
    ]);
  }

  truncate(input) {
    if (input.length > 5) {
      return input.substring(0, 20) + '...';
    }
    return input;
  }
}
