<div class="bg-white card mt-3 p-3" *ngIf="gateway">
  <div class="d-flex align-items-center mb-2">
    <i class="fa fa-check-circle fa-lg title-margin" *ngIf="complete()"></i>
    <i class="fa fa-times-circle fa-lg title-margin" *ngIf="incomplete()"></i>
    <div>Gateway</div>
  </div>
  <div class="d-flex align-items-center">
    <i class="fa fa-check ms-1 me-3" *ngIf="gateway.wifiType"></i>
    <i class="fa fa-times ms-1 small-margin" *ngIf="!gateway.wifiType"></i>
    <div class="small wrap">
      WiFi Type:
      <span class="grey">
        {{ gateway.wifiType ? gateway.wifiType : "N/A" }}
      </span>
    </div>
  </div>
  <div class="d-flex align-items-center">
    <i class="fa fa-check ms-1 me-3" *ngIf="gateway.gatewayDeviceId"></i>
    <i
      class="fa fa-times ms-1 small-margin"
      *ngIf="!gateway.gatewayDeviceId"
    ></i>
    <div class="small wrap">
      Gateway Device ID:
      <span class="grey">
        {{ gateway.gatewayDeviceId ? gateway.gatewayDeviceId : "N/A" }}
      </span>
    </div>
  </div>
  <div class="d-flex align-items-center">
    <i class="fa fa-check ms-1 me-3" *ngIf="gateway.networkName"></i>
    <i class="fa fa-times ms-1 small-margin" *ngIf="!gateway.networkName"></i>
    <div class="small wrap">
      Network Name:
      <span class="grey">
        {{ gateway.networkName ? gateway.networkName : "N/A" }}
      </span>
    </div>
  </div>
  <div class="d-flex align-items-center">
    <i class="fa fa-check ms-1 me-3" *ngIf="gateway.devicePassword"></i>
    <i
      class="fa fa-times ms-1 small-margin"
      *ngIf="!gateway.devicePassword"
    ></i>
    <div class="small wrap">
      Device Password:
      <span class="grey">
        {{ gateway.devicePassword ? gateway.devicePassword : "N/A" }}
      </span>
    </div>
  </div>
  <div class="d-flex align-items-center" *ngIf="gateway.wifiType == 'Static'">
    <i class="fa fa-check ms-1 me-3" *ngIf="gateway.ipAddress"></i>
    <i class="fa fa-times ms-1 small-margin" *ngIf="!gateway.ipAddress"></i>
    <div class="small wrap">
      IP Address:
      <span class="grey">
        {{ gateway.ipAddress ? gateway.ipAddress : "N/A" }}
      </span>
    </div>
  </div>
  <div class="d-flex align-items-center" *ngIf="gateway.wifiType == 'Static'">
    <i class="fa fa-check ms-1 me-3" *ngIf="gateway.networkMask"></i>
    <i class="fa fa-times ms-1 small-margin" *ngIf="!gateway.networkMask"></i>
    <div class="small wrap">
      Network Mask:
      <span class="grey">
        {{ gateway.networkMask ? gateway.networkMask : "N/A" }}
      </span>
    </div>
  </div>
  <div class="d-flex align-items-center" *ngIf="gateway.wifiType == 'Static'">
    <i class="fa fa-check ms-1 me-3" *ngIf="gateway.gatewayAddress"></i>
    <i
      class="fa fa-times ms-1 small-margin"
      *ngIf="!gateway.gatewayAddress"
    ></i>
    <div class="small wrap">
      Gateway Address:
      <span class="grey">
        {{ gateway.gatewayAddress ? gateway.gatewayAddress : "N/A" }}
      </span>
    </div>
  </div>
  <div class="d-flex align-items-center" *ngIf="gateway.wifiType == 'Static'">
    <i class="fa fa-check ms-1 me-3" *ngIf="gateway.dns"></i>
    <i class="fa fa-times ms-1 small-margin" *ngIf="!gateway.dns"></i>
    <div class="small wrap">
      DNS:
      <span class="grey">
        {{ gateway.dns ? gateway.dns : "N/A" }}
      </span>
    </div>
  </div>
</div>

<div class="bg-white card mt-3 p-3" *ngIf="!gateway">
  <div class="d-flex align-items-center">
    <i class="fa fa-times-circle fa-lg title-margin"></i>
    <div>No Gateway</div>
  </div>
</div>
