<div class="bg-white mt-3" *ngIf="sensors.length">
  <div *ngFor="let sensor of sensors; let i = index">
    <div class="d-flex align-items-center mb-2" [ngClass]="{ 'mt-3': i >= 1 }">
      <i class="fa fa-check-circle fa-lg title-margin" *ngIf="complete(sensor)"></i>
      <i class="fa fa-times-circle fa-lg title-margin" *ngIf="incomplete(sensor)"></i>
      <div>Sensor: {{ i + 1 }}</div>
    </div>
    <div class="d-flex align-items-center">
      <i class="fa fa-check ms-1 me-3" *ngIf="sensor.conveyorId"></i>
      <i class="fa fa-times ms-1 small-margin" *ngIf="!sensor.conveyorId"></i>
      <div class="small wrap">
        Conveyor ID:
        <span class="grey">
          {{ sensor.conveyorId ? sensor.conveyorId : "N/A" }}
        </span>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <i class="fa fa-check ms-1 me-3" *ngIf="sensor.sensorNickname"></i>
      <i class="fa fa-times ms-1 small-margin" *ngIf="!sensor.sensorNickname"></i>
      <div class="small wrap">
        Sensor Nickname:
        <span class="grey">
          {{ sensor.sensorNickname ? sensor.sensorNickname : "N/A" }}
        </span>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <i class="fa fa-check ms-1 me-3" *ngIf="sensor.sid"></i>
      <i class="fa fa-times ms-1 small-margin" *ngIf="!sensor.sid"></i>
      <div class="small wrap">
        Sensor Type:
        <span class="grey">
          {{ sensor.sid ? returnSensorType(sensor.sid) : "N/A" }}
        </span>
      </div>
    </div>
    <div class="d-flex align-items-center" *ngIf="containsRfidReader">
      <i class="fa fa-check ms-1 me-3" *ngIf="sensor.gatewayDeviceId"></i>
      <i class="fa fa-times ms-1 small-margin" *ngIf="!sensor.gatewayDeviceId"></i>
      <div class="small wrap">
        Gateway Device ID:
        <span class="grey">
          {{ sensor.gatewayDeviceId ? sensor.gatewayDeviceId : "N/A" }}
        </span>
      </div>
    </div>
    <div
      class="d-flex align-items-center"
      [ngClass]="{ 'optional-missing': emptyOptionalField(sensor.description) }"
    >
      <i class="fa fa-check ms-1 me-3" *ngIf="sensor.description"></i>
      <i class="fa fa-times ms-1 small-margin" *ngIf="!sensor.description"></i>
      <div class="small wrap">
        Description:
        <span class="grey">
          {{ sensor.description ? sensor.description : "N/A" }}
        </span>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <i class="fa fa-check ms-1 me-3" *ngIf="sensor.sid"></i>
      <i class="fa fa-times ms-1 small-margin" *ngIf="!sensor.sid"></i>
      <div class="small wrap">
        SID:
        <span class="grey">
          {{ sensor.sid ? sensor.sid : "N/A" }}
        </span>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <i class="fa fa-check ms-1 me-3" *ngIf="sensor.edid"></i>
      <i class="fa fa-times ms-1 small-margin" *ngIf="!sensor.edid"></i>
      <div class="small wrap">
        EDID:
        <span class="grey">
          {{ sensor.edid ? sensor.edid : "N/A" }}
        </span>
      </div>
    </div>
    <div class="d-flex align-items-center" *ngIf="returnSensorType(sensor.sid) == 'Drum Speed'">
      <i class="fa fa-check ms-1 me-3" *ngIf="sensor.role"></i>
      <i class="fa fa-times ms-1 small-margin" *ngIf="!sensor.role"></i>
      <div class="small wrap">
        Role:
        <span class="grey">
          {{ sensor.role ? sensor.role : "N/A" }}
        </span>
      </div>
    </div>
  </div>
</div>

<div class="bg-white mt-3" *ngIf="!sensors.length">
  <div class="d-flex align-items-center">
    <i class="fa fa-times-circle fa-lg title-margin"></i>
    <div>No Sensors for this Conveyor</div>
  </div>
</div>
