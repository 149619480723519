import { TranslateService } from './../../../services/translate.service';
import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { UtilService } from '../../../utils/util.service';

@Injectable()
export class BreadcrumbService {
  private breadcrumbs$: Observable<Breadcrumb[]> = new Observable<Breadcrumb[]>();
  private breadcrumbs: Breadcrumb[] = [];
  private subscribers: Subscriber<Breadcrumb[]>[] = [];

  constructor(private router: Router, private utils: UtilService, private t: TranslateService) {
    this.breadcrumbs$ = new Observable<Breadcrumb[]>((sub) => {
      this.subscribers.push(sub);
      sub.next(this.breadcrumbs);
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart && event.navigationTrigger == 'popstate') {
        // check if this route has been visited already
        const crumbs = this.breadcrumbs.map((crumb) => crumb.routerLink.join('/'));
        if (crumbs.find((crumb) => crumb.includes(event.url))) {
          this.popBreadcrumb();
        }
      }
    });
  }

  popBreadcrumb() {
    this.breadcrumbs.pop();
    this.updateBreadcrumbsObservable();

    const length = this.breadcrumbs.length;
    const history = this.breadcrumbs[length - 1];

    const breadcrumb: Breadcrumb = history || {
      name: 'Overview',
      routerLink: ['/'],
      queryParams: {},
    };
    this.router.navigate(breadcrumb.routerLink, { queryParams: breadcrumb.queryParams });
  }

  addQueryParamsToBreadcrumb(breadcrumb: Breadcrumb, params: string[]) {
    const crumb = this.breadcrumbs.find((crumb) => crumb.name == breadcrumb.name);
    if (!crumb) {
      return;
    }
    crumb.queryParams = params;
    this.updateBreadcrumbsObservable();
  }

  removeBreadcrumbsAfterBreadcrumb(breadcrumb: Breadcrumb) {
    const index = this.breadcrumbs.indexOf(breadcrumb);
    this.breadcrumbs = this.breadcrumbs.slice(0, index);
    this.updateBreadcrumbsObservable();
  }

  pushBreadcrumb(breadcrumb: Breadcrumb) {
    if (this.breadcrumbs.find((crumb) => crumb.name == breadcrumb.name)) {
      return;
    }

    breadcrumb = this.translateComponentBreadcrumbs(breadcrumb);
    this.breadcrumbs.push(breadcrumb);
    this.updateBreadcrumbsObservable();
  }

  setBreadcrumbs(breadcrumbs: Breadcrumb[]) {
    this.breadcrumbs = breadcrumbs;
    this.updateBreadcrumbsObservable();
  }

  private updateBreadcrumbsObservable() {
    for (const subscriber of this.subscribers) {
      subscriber.next(this.utils.deepCopy(this.breadcrumbs));
    }
  }

  subscribeToBreadcrumbChanges(callback: (breadcrumbs: Breadcrumb[]) => void): Subscription {
    return this.breadcrumbs$.subscribe(callback);
  }

  unsubscribe(subscription: Subscription) {
    if (subscription) {
      subscription.unsubscribe();
    }

    this.subscribers = this.subscribers.filter((subscriber) => !subscriber.closed);
  }

  translateComponentBreadcrumbs(breadcrumb: Breadcrumb) {
    if (breadcrumb.name == 'Surveys') {
      breadcrumb.name = this.t.translate('_SURVEYS');
    }
    return breadcrumb;
  }
}

export class Breadcrumb {
  routerLink: string[];
  name: string;
  queryParams?: any;

  constructor(name, routerLink) {
    this.name = name;
    this.routerLink = routerLink;
  }
}
