import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { AppFormGroup } from '../../../utils/app-form-group';
import { CustomValidators } from './../../../utils/custom-validators';

import { Belt, JointType } from '../../../models/belt.model';
import { Image } from './../../../shared/components/image-input/models/image';
import { Machine } from './../../../models/machine.model';
import { Survey } from './../../../models/survey.model';

import { AlertService } from './../../../shared/services/alert/alert.service';
import { CustomerService } from '../../../services/customer/customer.service';
import { FileService } from './../../../services/file/file.service';
import { LoadingService } from './../../../shared/services/loading/loading.service';
import { ModalService } from './../../../services/modal/modal.service';
import { TranslateService } from './../../../services/translate.service';
import { UtilService } from './../../../utils/util.service';

import * as BeltSelectors from '../../../state/belt/belt.selector';

@Component({
  selector: 'app-belt-input',
  templateUrl: './belt-input.component.html',
  styleUrls: ['./belt-input.component.scss'],
})
export class BeltInputComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() beltInput: Belt;
  @Input() survey: Survey;
  @Output() beltChange: EventEmitter<Belt> = new EventEmitter<Belt>();

  public beltForm = new AppFormGroup({
    wtl: new FormControl('', [Validators.maxLength(10), CustomValidators.cannotBeWhitespace]),
    productionLine: new FormControl('', [Validators.maxLength(50)]),
    productReference: new FormControl('', [
      Validators.required,
      CustomValidators.cannotBeWhitespace,
      Validators.maxLength(100),
    ]),
    material: new FormControl('', [Validators.maxLength(250)]),
    length: new FormControl(null, [Validators.required, Validators.min(1)]), // Find actual minimums
    width: new FormControl(null, [Validators.required, Validators.min(1)]), // Find actual minimums
    quantity: new FormControl(1, [Validators.required, Validators.min(1)]),
    carriersBordoflex: new FormControl('', [
      CustomValidators.cannotBeWhitespace,
      Validators.maxLength(250),
    ]),
    beltAccess: new FormControl('', [
      CustomValidators.cannotBeWhitespace,
      Validators.maxLength(250),
    ]),
    trackingGuide: new FormControl('', [
      CustomValidators.cannotBeWhitespace,
      Validators.maxLength(250),
    ]),
    comments: new FormControl('', [CustomValidators.cannotBeWhitespace, Validators.maxLength(250)]),
    sprocketBore: new FormControl('', [CustomValidators.cannotBeWhitespace]),
    sprocketPd: new FormControl('', [CustomValidators.cannotBeWhitespace]),
    pinMaterial: new FormControl('', [
      CustomValidators.cannotBeWhitespace,
      Validators.maxLength(250),
    ]),
    beltMaterial: new FormControl('', [
      CustomValidators.cannotBeWhitespace,
      Validators.maxLength(250),
    ]),
    beltColour: new FormControl('', [
      CustomValidators.cannotBeWhitespace,
      Validators.maxLength(250),
    ]),
    beltType: new FormControl('', [CustomValidators.cannotBeWhitespace, Validators.maxLength(250)]),
    itemReference: new FormControl('', [
      CustomValidators.cannotBeWhitespace,
      Validators.maxLength(250),
    ]),
  });
  
  public belt: Belt;

  public jointTypes = this.mapKeyValuesFromEnum(JointType);
  public weldTypes: string[] = [
    'Finger Splice',
    'Business Splice',
    'Finger-Over-Finger',
    'Step Splice',
    'Quick Splice',
    'Classic',
  ];
  public weldDirections: string[] = ['Straight', 'Diagonal'];
  public beltAccessTypes: string[] = [
    'Head height',
    '2m height',
    '3m height',
    '4m height',
    'Hot environment',
    'Cold environment',
    'Access via permanent gantry',
    'Difficult access - discuss with customer',
    'Belt fully enclosed behind casing',
    'Scissor lift / mobile platform available on site',
  ];
  public beltAccess: string[] = [];

  private imagePreview: Image = new Image();
  private existingImage: Image;
  public ammcareItemOptionId: string;

  public highlightErrors: boolean;
  public isImportFormSubmitted: boolean;
  public isSavingItem: boolean;

  public syntheticMaterials$: Observable<any>;
  public modularMaterials$: Observable<any>;
  public modularTypes$: Observable<any>;
  public modularColours$: Observable<any>;

  private image: Image = new Image();
  private ammcareItem: Machine;
  private ammcareItems: Machine[] = [];
  private currentProdLine: string;
  private surveyProdLines: string[] = [];
  private bsModalRef: BsModalRef;

  public availableFields: any[] = [];
  public selectedFields: any[] = [];
  public overwrittenFields: any[] = [];
  public importItemFields: any[] = [];

  constructor(
    private alertService: AlertService,
    private customerService: CustomerService,
    private fileService: FileService,
    private loadingService: LoadingService,
    private modalService: ModalService,
    private store: Store,
    private utils: UtilService,
    public t: TranslateService
  ) {
    this.initImportItemFields();
  }

  public async ngOnInit() {
    this.syntheticMaterials$ = this.store.select(BeltSelectors.selectSyntheticMaterials);
    this.modularMaterials$ = this.store.select(BeltSelectors.selectModularMaterials);
    this.modularTypes$ = this.store.select(BeltSelectors.selectModularTypes);
    this.modularColours$ = this.store.select(BeltSelectors.selectModularColours);
  }

  public async ngOnChanges(changes: SimpleChanges) {
    if (changes.beltInput) {
      if (this.beltInput && !this.belt) {
        if (this.beltInput.beltAccess?.length) {
          this.beltAccess = this.beltInput.beltAccess.split(',');
        }

        this.belt = Object.assign({}, this.beltInput);
        this.setBeltProdLine(this.belt.productionLine);

        if (this.belt.image?.displayImage) {
          this.imagePreview = Image.fromUrl(
            (this.belt.image?.displayImage as any).changingThisBreaksApplicationSecurity
          );
        } else {
          this.imagePreview = this.image =
            this.belt.image ?? Image.fromUrl(this.belt?.attachmentUrl);
        }
        this.existingImage = this.imagePreview;
        this.checkSynOrMod();
      }

      // TODO: update image display url with the attachment url if there is one, then don't change the attachment url on the belt
    }

    if (changes.survey) {
      if (this.survey) {
        const belts$: Observable<Belt[]> = this.store.select(
          BeltSelectors.selectSurveyBelts({ surveyId: this.survey.id })
        );
        belts$.subscribe((belts) => {
          let beltProdLines: any[] = belts.filter((belt) => belt.productionLine && !belt.deleted);
          beltProdLines = beltProdLines.sort(
            (a, b) => new Date(b.updated).getTime() - new Date(a.updated).getTime()
          );
          beltProdLines = beltProdLines.map((belt) => belt.productionLine);

          const prodLineNamesSet = new Set(beltProdLines);
          this.surveyProdLines = [...prodLineNamesSet].map((prodLineName) => prodLineName);

          if (!this.belt?.productionLine && !this.belt?.id) {
            this.setBeltProdLine(this.surveyProdLines[0]);
          }
        });

        await this.fetchAmmcareItems();
      }
    }
  }

  private mapKeyValuesFromEnum(enumType): { key: string; value: string }[] {
    return Object.keys(enumType).map((type) => ({
      key: type,
      value: enumType[type],
    }));
  }

  private async fetchAmmcareItems() {
    try {
      this.ammcareItems = await this.customerService.fetchCustomerAmmcareBelts(
        this.survey.customerId
      );

      this.ammcareItems = this.ammcareItems.sort((a: any, b: any): any => {
        return this.utils.compare(a.machine_id?.toLowerCase(), b.machine_id?.toLowerCase());
      });
    } catch (err) {
      console.log(err);
    }
  }

  public onImageInputChange(image) {
    this.image = image;
    if (!image.file) this.belt.attachmentUrl = null;
  }

  private async updateImage() {
    try {
      const isImageFileSet = !!this.image.file;
      const isImageDisplayImageSet = !!this.image.displayImage;
      const isImageSame = this.image.displayImage == this.existingImage.displayImage;
      const isFirstImageImport = isImageDisplayImageSet && !this.existingImage;
      const isOverwriteImport = isImageDisplayImageSet && !isImageSame;

      if (isImageFileSet || isFirstImageImport || isOverwriteImport) {
        if (this.image.file) {
          this.belt.image = await this.compressImageFile(this.image);
        } else if (this.image.displayImage && typeof this.image.displayImage == 'string') {
          this.belt.attachmentUrl = this.image.displayImage;
        }
      } else if (isImageSame) {
        console.log('No image change');
      } else {
        console.log('Invalid image handling');
      }
    } catch (err) {
      this.alertService.handleHttpError(err);
    }
  }

  private async compressImageFile(image: Image): Promise<Image> {
    if (image && image.displayImage && typeof image.displayImage !== 'string') {
      if (image.file.size > 250000) {
        const compressedImageBlob: Blob = await this.fileService.compressImage(image.file);
        const compressedImageFile = new File(
          [compressedImageBlob],
          `${this.belt.id}-${new Date().getTime()}.jpg`
        );
        image.file = compressedImageFile;
        return image;
      } else {
        return Object.assign({}, image);
      }
    }
  }

  public setBeltProdLine(productionLine: string) {
    this.belt = { ...this.belt, productionLine };
    this.currentProdLine = productionLine;
    this.beltForm.get('productionLine').setValue(productionLine);
  }

  public async onSave() {
    this.isSavingItem = true;

    this.setBeltProdLine(this.currentProdLine);
    this.belt.length = this.belt.length || 0;
    this.belt.width = this.belt.width || 0;
    this.belt.beltAccess = this.beltAccess.toString();

    this.beltForm.markAsSubmitted();
    if (this.beltForm.submitted && !this.belt.synOrMod) {
      this.isSavingItem = false;
      return;
    }

    if (this.belt.material && this.belt.synOrMod === 'synthetic') {
      if ((this.belt.material as any).label) {
        this.belt.material = (this.belt.material as any).label;
      }
    }

    const isSynWithoutJoint = this.belt.synOrMod === 'synthetic' && !this.belt.joint;
    if (this.beltForm.invalid || isSynWithoutJoint) {
      this.highlightErrors = true;
      this.alertService.alertWarning(this.t.translate('_BELT_INFORMATION_INCOMPLETE'));
      this.isSavingItem = false;
      return;
    }

    if (this.belt.synOrMod === 'synthetic') {
      if (this.belt.joint['label']) {
        this.belt.joint = this.belt.joint['label'];
      }
    }

    this.loadingService.show();
    await this.updateImage();
    this.loadingService.hide();

    this.beltChange.emit(this.belt);
  }

  public openImportDialog(dialog) {
    this.isImportFormSubmitted = false;
    this.ammcareItemOptionId = null;
    this.ammcareItem = null;
    this.resetImportFields();
    this.bsModalRef = this.modalService.show(dialog, { class: 'modal-md' });
  }

  public closeImportDialog() {
    this.bsModalRef.hide();
  }

  public toggleImport(item) {
    if (item.disabled) return;

    this.isImportFormSubmitted = false;
    item.selected = !item.selected;

    this.validateImportItem();
  }

  public setAmmcareImportItem() {
    this.isImportFormSubmitted = false;
    this.ammcareItem = this.ammcareItems.find((x) => this.ammcareItemOptionId == x.id);

    if (!this.ammcareItem) {
      this.importItemFields.forEach((field) => {
        field.disabled = true;
      });
    } else {
      this.importItemFields.forEach((field) => {
        field.selected = true;

        if (field.name == 'image') {
          field.disabled = !this.ammcareItem.images?.length;
        } else {
          field.disabled = !this.ammcareItem[field.name];
        }
      });

      this.validateImportItem();
    }
  }

  private checkAvailableFields() {
    this.availableFields = this.importItemFields.filter((field) => !field.disabled);
  }

  private checkSelectedFields() {
    this.selectedFields = this.importItemFields.filter(
      (field) => field.selected && !field.disabled
    );
  }

  private checkForOverwrite() {
    this.overwrittenFields = [];
    this.selectedFields.forEach((field) => {
      if (field.name == 'image') {
        const hasImage = this.imagePreview.displayImage || this.imagePreview.file;
        if (hasImage) {
          this.overwrittenFields.push(field.translation);
        }
      } else {
        const value = this.beltForm.get(field.name).value;
        if (value) {
          this.overwrittenFields.push(field.translation);
        }
      }
    });
  }

  private checkSynOrMod() {
    if (this.belt.synOrMod === 'synthetic') {
      this.initSyntheticFields();
    } else if (this.belt.synOrMod === 'modular') {
      this.initModularFields();
    }
  }

  private initSyntheticFields() {
    // disable modular fields
    this.beltForm.get('sprocketBore').disable();
    this.beltForm.get('sprocketPd').disable();
    this.beltForm.get('pinMaterial').disable();
    this.beltForm.get('beltColour').disable();
    this.beltForm.get('beltMaterial').disable();
    this.beltForm.get('beltType').disable();

    // enable synthetic fields
    this.beltForm.get('material').enable();
  }

  private initModularFields() {
    // disable synthetic fields
    this.beltForm.get('material').disable();

    this.belt.joint = null;

    // enable modular fields
    this.beltForm.get('sprocketBore').enable();
    this.beltForm.get('sprocketPd').enable();
    this.beltForm.get('pinMaterial').enable();
    this.beltForm.get('beltColour').enable();
    this.beltForm.get('beltMaterial').enable();
    this.beltForm.get('beltType').enable();
  }

  private initImportItemFields() {
    this.importItemFields = [
      {
        name: 'image',
        logo: 'imagesmode',
        translation: `${this.t.translate('_IMAGE')}`,
        disabled: true,
        selected: true,
      },
      {
        name: 'productionLine',
        logo: 'conveyor_belt',
        translation: `${this.t.translate('_PRODUCTION_LINE')}`,
        disabled: true,
        selected: true,
      },
      {
        name: 'length',
        logo: 'height',
        translation: `${this.t.translate('_LENGTH')}`,
        disabled: true,
        selected: true,
      },
      {
        name: 'width',
        logo: 'width',
        translation: `${this.t.translate('_WIDTH')}`,
        disabled: true,
        selected: true,
      },
    ];
  }

  private resetImportFields() {
    this.selectedFields = [];
    this.availableFields = [];
    this.overwrittenFields = [];
    this.initImportItemFields();
  }

  private validateImportItem(): boolean {
    this.checkAvailableFields();
    this.checkSelectedFields();
    this.checkForOverwrite();

    if (!this.ammcareItemOptionId) return;

    if (!this.availableFields.length) {
      this.alertService.alertWarning('Item has no values to import.');
      return;
    }

    if (!this.selectedFields.length) {
      this.alertService.alertWarning('Please select at least one value.');
      return;
    }

    return true;
  }

  async importAmmcareItem() {
    this.isImportFormSubmitted = true;

    const isValid = this.validateImportItem();
    if (!isValid) return;

    try {
      const ammcareMachine = this.ammcareItems.find(
        (beltConfig) => this.ammcareItemOptionId == beltConfig.id
      );

      for (let field of this.importItemFields) {
        if (field.selected && !field.disabled) {
          switch (field.name) {
            case 'image':
              this.imagePreview = this.image = Image.fromUrl(ammcareMachine.images[0]?.imageUrl);
              break;
            case 'productionLine':
              this.currentProdLine = ammcareMachine.productionLine;
              this.setBeltProdLine(ammcareMachine.productionLine);
              break;
            case 'length':
              this.belt.length = ammcareMachine.length;
              break;
            case 'width':
              this.belt.width = ammcareMachine.width;
              break;
            default:
              break;
          }
        }
      }

      let importedProps = [];
      importedProps = this.importItemFields
        .filter((field) => field.selected && !field.disabled)
        .map((field) => field.translation);

      if (this.belt.image && !ammcareMachine.images[0]?.imageUrl) {
        this.alertService.alertWarning(this.t.translate('_NO_IMAGE_FOUND'));
      }
      this.alertService.alertSuccess(
        `${this.t.translate('_IMPORT_SUCCESSFUL_FOR')} ${importedProps.join(', ')}`
      );

      this.closeImportDialog();
    } catch (err) {
      this.alertService.handleHttpError(err);
    }
  }
}
