import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  templateUrl: 'info-modal.component.html',
})
export class InfoModalComponent {
  public options: InfoModalOptions;
  public hide: boolean = false;

  constructor(private bsModalRef: BsModalRef) {}

  onConfirm() {
    this.options.confirm();
    this.close();
  }

  close() {
    this.bsModalRef.hide();
  }

  toggleHide() {
    this.hide = !this.hide;
  }
}

export class InfoModalOptions {
  title: string;
  message: string;
  confirmButtonText?: string;
  confirm: () => void;
}
