import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as localforage from 'localforage';

import * as BeltActions from '../../state/belt/belt.actions';
import * as CustomerActions from '../../state/customer/customer.actions';
import * as SurveyActions from '../../state/survey/survey.actions';

import { PromiseQueue } from '../../../app/utils/promise-queue';
import { State } from '../../state/core.state';
import { Model } from '../../../app/state/state-utils';
import { Survey } from './../../models/survey.model';
import { Belt } from './../../models/belt.model';
import { Customer } from '../../models/customer.model';

export class SynchronisationData {
  customers?: Customer[] = [];
  surveys?: Survey[] = [];
  belts?: Belt[] = [];
}

@Injectable({ providedIn: 'root' })
export class ForageService {
  private state$ = this.store.select((state: State) => state);

  constructor(private store: Store, private promiseQueue: PromiseQueue) {}

  public async init(): Promise<void> {
    await this.getRetiredLocalDataAndMigrate();
    await this.migrateCustomerData();
    await this.initStateFromForage();

    this.state$.subscribe((state: State) => {
      this.promiseQueue.push(() => this.backupStateToForage(state));
    });
  }

  private async getRetiredLocalDataAndMigrate() {
    try {
      const masterData: SynchronisationData = await localforage.getItem('MASTER_DATA');
      const clientData: SynchronisationData = await localforage.getItem('CLIENT_DATA');

      if (!masterData || !clientData) return;

      let fields = Object.keys(masterData);
      let data: SynchronisationData = {};

      for (const field of fields) {
        data[field] = this.mergeClientDataAndMasterData(clientData[field], masterData[field]);
        data[field] = this.replaceOldStateFlagsWithDirtyFlag(data[field]);
      }

      await localforage.setItem('AMMSCAN_DATA', data);
      await localforage.removeItem('MASTER_DATA');
      await localforage.removeItem('CLIENT_DATA');
    } catch (err) {
      console.log('unable to migrate local data');
    }
  }

  private async migrateCustomerData() {
    const data = await this.getLocalDataByKey('AMMSCAN_DATA');
    data['customers'] = this.replaceCustomerUserGroupIdWithId(data['customers']);
    await localforage.setItem('AMMSCAN_DATA', data);
  }

  private mergeClientDataAndMasterData<T extends Model>(clientData: T[], masterData: T[]): T[] {
    let result: T[] = [];
    let clientItemMap: { [id: string]: T } = {};
    let masterItemMap: { [id: string]: boolean } = {};

    for (const item of clientData) clientItemMap[item.id] = item;

    for (const masterItem of masterData) {
      const clientItem = clientItemMap[masterItem.id];
      if (clientItem) {
        result.push(this.selectItemUsingMergeLogic(masterItem, clientItem));
      } else {
        result.push(masterItem);
      }
      masterItemMap[masterItem.id] = true;
    }

    for (const item of clientData) {
      if (!masterItemMap[item.id]) result.push(item);
    }

    return result;
  }

  private selectItemUsingMergeLogic<T extends Model>(masterItem: T, clientItem: T): T {
    if (masterItem.deleted && !clientItem.deleted) return masterItem;
    return clientItem;
  }

  private replaceOldStateFlagsWithDirtyFlag(items: Model[]): Model[] {
    return items.map((item) => {
      item.dirty = item.dirty ?? item['hasLocalChange'] ?? undefined;
      delete item['hasLocalChange'];
      delete item['hasServerRecord'];
      return item;
    });
  }

  private replaceCustomerUserGroupIdWithId(customers) {
    return customers.map((customer) => {
      customer.id = customer.id ?? customer.user_group_id;
      delete customer['user_group_id'];
      return customer;
    });
  }

  private async initStateFromForage() {
    try {
      const data = await this.getLocalDataByKey('AMMSCAN_DATA');

      this.store.dispatch(BeltActions.setBeltsSuccess({ belts: data.belts }));
      this.store.dispatch(CustomerActions.setCustomersSuccess({ customers: data.customers }));
      this.store.dispatch(SurveyActions.setSurveysSuccess({ surveys: data.surveys }));
    } catch (err) {
      console.log(err);
    }
  }

  private async getLocalDataByKey(key): Promise<SynchronisationData> {
    try {
      let data: SynchronisationData = await localforage.getItem(key);
      if (!data) data = new SynchronisationData();
      return data;
    } catch (err) {
      console.error(err);
      return new SynchronisationData();
    }
  }

  private async backupStateToForage(state: State) {
    await this.saveDataToForage('AMMSCAN_DATA', {
      surveys: state.survey.surveys,
      belts: state.belt.belts,
      customers: state.customer.customers,
    });
  }

  private async saveDataToForage(key: string, data: SynchronisationData) {
    try {
      await localforage.setItem(key, data);
    } catch (err) {
      console.log('error on save forage data. key, data:', key, data);
      console.error(err);
    }
  }

  public async clearForage() {
    await localforage.clear();
  }
}
