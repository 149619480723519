import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PromiseQueue {
  private busy: boolean = false;
  private promiseFunctions: (() => Promise<void>)[] = [];

  public push(promiseFunction: () => Promise<void>) {
    this.promiseFunctions.push(promiseFunction);

    if (this.busy) return;

    this.handlePromiseQueue();
  }

  private async handlePromiseQueue() {
    this.busy = true;

    let activePromiseFunction = this.promiseFunctions.shift();

    while (activePromiseFunction) {
      try {
        await activePromiseFunction();
      } catch (err) {
        // handle failure
      }
      activePromiseFunction = this.promiseFunctions.shift();
    }

    this.busy = false;
  }
}
