import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import * as CscSelectors from '../../state/csc/csc.selector';
import { CscState } from '../../state/csc/csc.state';

import { TranslateService } from '../../services/translate.service';
import { OnlineService } from '../../services/online-service/online.service';

@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
})
export class AppLayoutComponent implements OnInit, OnDestroy {
  public online: boolean;
  private subscriptions: Subscription[] = [];

  $cscs = this.store.select(CscSelectors.selectCscs);

  constructor(
    private store: Store,
    private onlineService: OnlineService,
    public t: TranslateService
  ) {}

  public ngOnInit() {
    const onlineSub = this.onlineService.subscribe((online) => (this.online = online));
    this.subscriptions.push(onlineSub);
  }

  public ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  public onReloadPageClicked() {
    window.location.reload();
  }

  public isCscsStateValid(state: CscState): boolean {
    if (!state || !state.loadedAt) return true;
    return !!state.cscs.length;
  }
}
