import { TranslateService } from './../../../../services/translate.service';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';

import { BeltFilter } from '../belt-list.component';
import { Belt } from './../../../../models/belt.model';
import { Survey } from './../../../../models/survey.model';

import { UtilService } from './../../../../utils/util.service';

@Component({
  selector: 'app-mobile-belt-list',
  templateUrl: './mobile-belt-list.component.html',
  styleUrl: './mobile-belt-list.component.scss',
})
export class MobileBeltListComponent {
  @Input() survey: Survey;
  @Input() appliedFilters: BeltFilter;
  @Input() filterCount: number;
  @Input() hasActiveFilter: boolean;
  @Input() belts: Belt[] = [];

  paginatedBelts: Belt[] = [];

  @Input() searchText: string;
  @Output() searchTextChange: EventEmitter<string> = new EventEmitter<string>();

  @Output() itemClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() copyClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadSurveyCsvClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(private utils: UtilService, public t: TranslateService) {
    document.addEventListener('scroll', (event) => {
      const element = document.body;

      const scroll = window.scrollY;
      const scrollAvailable = element.scrollHeight - element.clientHeight;
      const available = 100 - (scroll / scrollAvailable) * 100;

      if (available < 20) this.onScrollDown();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.belts) {
      this.paginatedBelts = this.utils.sortAlphaNumeric(this.paginatedBelts, 'created', false);
      this.populateRows();
    }
  }

  private populateRows() {
    if (!this.belts?.length) {
      this.paginatedBelts = [];
      return;
    }

    const tableRows = Math.min(20, this.belts.length);
    const displayedRows = Math.min(this.paginatedBelts.length, this.belts.length);
    const maxItems = Math.max(tableRows, displayedRows);

    const rows = [];
    for (let i = 0; i < maxItems; i++) {
      rows.push(this.belts[i]);
    }
    this.paginatedBelts = rows;
  }

  onScrollDown() {
    if (this.paginatedBelts.length < this.belts.length) {
      const endIndex = Math.min(this.paginatedBelts.length + 20, this.belts.length);
      for (let i = this.paginatedBelts.length; i < endIndex; i++) {
        this.paginatedBelts.push(this.belts[i]);
      }
    }
  }

  onItemClick(belt: Belt) {
    this.itemClick.emit(belt);
  }
  onCopyClick(belt: Belt) {
    this.copyClick.emit(belt);
  }
  onRemoveClick(belt: Belt) {
    this.removeClick.emit(belt);
  }
  onFilterButtonClick() {
    this.filterButtonClick.emit();
  }
  onDownloadSurveyCsvClick() {
    this.downloadSurveyCsvClick.emit();
  }
  onSearchTextChange() {
    this.searchTextChange.emit(this.searchText);
  }
}
