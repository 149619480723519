export class CBeltMachine {
  id: string;

  machineName: string;
  aliasName: string;
  organizationId: string;

  static fromJson(json: any): CBeltMachine {
    let machine = new CBeltMachine();
    machine.id = json.id;
    machine.machineName = json.machineName;
    machine.aliasName = json.aliasName;
    machine.organizationId = json.organizationId;
    return machine;
  }
}
