import { Model } from '../state/state-utils';

export class Customer implements Model {
  id: string;
  created: Date = new Date();
  updated: Date = new Date();
  createdBy: string;
  updatedBy: string;

  deleted: boolean;

  customer_name: string;
  customer_no: string;

  opco_id: string;
  userCount: number;
  customerNumber: string;
  logo_url: string;
  hasAmmcareConnect: boolean;

  dirty: boolean = true;

  static fromJson(json): Customer {
    let customer = new Customer();
    customer.id = json.id;
    customer.customer_name = json.customer_name;
    customer.customerNumber = json.customerNumber;
    return customer;
  }

  static fromServer(json) {
    let customer = new Customer();
    customer.id = json.id;
    customer.customer_name = json.name;
    customer.customerNumber = json.customerNumber;
    return customer;
  }
}
