import { cloneDeep } from 'lodash';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CBelt } from 'src/app/models/cBelt.model';

import { BeltsService } from 'src/app/services/c-belt/belts.service';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';

import { take } from 'rxjs';
import { Store } from '@ngrx/store';
import { ConfigurationState } from 'src/app/state/installation/installation.state';
import * as InstallationActions from 'src/app/state/installation/installation.actions';
import * as InstallationSelectors from '../../../../state/installation/installation.selector';

@Component({
  selector: 'app-cbelt-list',
  templateUrl: './cbelt-list.component.html',
  styleUrl: './cbelt-list.component.scss',
})
export class CbeltListComponent implements OnInit {
  companyId: string;
  belts: CBelt[] = [];
  current: ConfigurationState = null;
  loading: boolean = false;

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private beltService: BeltsService,
    private alertService: AlertService,
    private loadingService: LoadingService
  ) {}

  async ngOnInit(): Promise<void> {
    this.loadingService.show();
    this.loading = true;
    try {
      this.route.params.subscribe((params) => {
        this.companyId = params['company_id'];
      });
      this.subscribeToCurrentConfiguration();
      this.belts = await this.beltService.getAllBelts();
      this.belts = this.belts.filter((x) => !x.conveyorId);
    } catch (err) {
      this.loadingService.hide();
      this.alertService.handleError(err);
    }
    this.loading = false;
    this.loadingService.hide();
  }

  subscribeToCurrentConfiguration() {
    const select = InstallationSelectors.selectCurrentSummary();
    this.store
      .select(select)
      .pipe(take(1))
      .subscribe((current) => {
        this.current = cloneDeep(current);
      });
  }

  selectBelt(belt: CBelt) {
    this.store.dispatch(InstallationActions.setCurrentBelt({ belt: belt }));
    this.router.navigate([
      '/installing',
      this.companyId,
      'conveyor-reader-configuration',
      'pair-belt-and-conveyor',
      this.current.conveyor.aliasName,
    ]);
  }

  public navToAddBelt() {
    this.store.dispatch(InstallationActions.setCurrentBelt({ belt: null }));
    this.router.navigate(['fitting']);
  }
}
