export class Conveyor {
  id: string;

  cpr: string;
  machineId: string;
  aliasName: string;
  description: string;
  diameter: Number;
  idleDrumDiameter: Number;
  customerNumber: string;

  static fromJson(json: any): Conveyor {
    let conveyor = new Conveyor();
    conveyor.id = json.id;
    conveyor.diameter = json.diameter;
    conveyor.idleDrumDiameter = json.idleDrumDiameter;
    conveyor.machineId = json.machineId;
    conveyor.aliasName = json.aliasName;
    conveyor.description = json.description;
    return conveyor;
  }
}
