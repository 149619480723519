import { cloneDeep } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, NgZone, OnInit } from '@angular/core';

import { Gateway } from 'src/app/models/gateway.model';

import { AlertService } from 'src/app/shared/services/alert/alert.service';

import { take } from 'rxjs';
import { Store } from '@ngrx/store';
import { ConfigurationState } from 'src/app/state/installation/installation.state';
import * as InstallationActions from 'src/app/state/installation/installation.actions';
import * as InstallationSelectors from 'src/app/state/installation/installation.selector';

@Component({
  selector: 'app-new-gateway-device',
  templateUrl: './new-gateway-device.component.html',
  styleUrls: ['./new-gateway-device.component.scss'],
})
export class NewGatewayDeviceComponent implements OnInit {
  editing: boolean;
  baseRoute: string;
  companyId: string;
  current: ConfigurationState = null;
  gatewayDevice: Gateway = new Gateway();

  qrConfig = {
    preferFrontCamera: false,
    showFlipCameraButton: true,
    showTorchButton: true,
    torchOn: false,
    saveHistory: true,
    prompt: 'Place a barcode inside the scan area',
    resultDisplayDuration: 500,
    formats: 'QR_CODE,PDF_417',
    orientation: 'portrait',
    disableAnimations: true,
    disableSuccessBeep: false,
  };

  constructor(
    private store: Store,
    private router: Router,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.route.url.subscribe((route) => {
      this.baseRoute = route[0].path;
    });
    this.route.params.subscribe((params) => {
      this.companyId = params['company_id'];
      this.subscribeToCurrentConfiguration();
    });
  }

  subscribeToCurrentConfiguration() {
    const select = InstallationSelectors.selectCurrentSummary();
    this.store
      .select(select)
      .pipe(take(1))
      .subscribe((current) => {
        this.current = cloneDeep(current);
        this.editing = this.current.editing;
        if (this.current.gateway) {
          this.gatewayDevice = this.current.gateway;
        }
      });
  }

  openAndScanQR() {
    window['cordova'].plugins['barcodeScanner'].scan(
      (result) => this.ngZone.run(() => this.onQRScanned(result.text)),
      (err) => this.ngZone.run(() => this.onQRScanError(err)),
      this.qrConfig
    );
  }

  onQRScanned(gatewayId: string) {
    this.gatewayDevice.gatewayDeviceId = gatewayId;
  }

  onQRScanError(err) {
    this.alertService.alertError('QR Scan Failed: ' + JSON.stringify(err));
  }

  isValid() {
    return this.gatewayDevice.gatewayDeviceId;
  }

  navToWifiSetup() {
    if (!this.isValid()) {
      this.alertService.alertError('Fill in Gateway Device ID before continuing');
      return;
    }
    this.gatewayDevice.id = this.gatewayDevice.gatewayDeviceId;
    this.store.dispatch(
      InstallationActions.setCurrentGatewayDevice({
        gatewayDevice: this.gatewayDevice,
      })
    );
    this.router.navigate([
      this.baseRoute,
      this.companyId,
      'gateway-configuration',
      'new-gateway-device',
      'configure-wifi',
    ]);
  }

  navTo4gConfig() {
    const gateway = new Gateway();
    gateway.wifiType = '4g Device';
    this.store.dispatch(
      InstallationActions.updateGatewayDevice({
        gatewayDevice: gateway,
      })
    );
    this.alertService.alertSuccess('Wifi selected as 4g Device');
    this.router.navigate([this.baseRoute, this.companyId]);
  }
}
