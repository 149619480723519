import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

import { Belt } from './../../models/belt.model';
import { environment } from '../../../environments/environment';

import * as BeltActions from '../belt/belt.actions';
import * as UserActions from '../user/user.actions';

@Injectable()
export class BeltEffects {
  constructor(private actions$: Actions<any>, private httpClient: HttpClient) {}

  fetchBelts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BeltActions.fetchBelts, UserActions.loginSuccess),

      switchMap(() => {
        return this.httpClient.get<Belt[]>(`${environment.baseUrl}/belts`).pipe(
          map((belts) => {
            if (!belts) {
              return BeltActions.fetchBeltsFailed({
                error: { message: 'Something went wrong.' },
              });
            }

            return BeltActions.fetchBeltsSuccess({ belts });
          }),
          catchError((error) => of(BeltActions.fetchBeltsFailed({ error })))
        );
      })
    )
  );

  fetchBeltById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BeltActions.fetchBeltById),

      switchMap((action) => {
        return this.httpClient
          .get<Belt>(`${environment.baseUrl}/belts/${action.id}`)
          .pipe(
            map((data) => {
              if (!data) {
                return BeltActions.fetchBeltFailed({
                  error: { message: 'Something went wrong.' },
                });
              }

              return BeltActions.fetchBeltSuccess({
                belt: data,
              });
            }),
            catchError((error) => of(BeltActions.fetchBeltFailed({ error })))
          );
      })
    )
  );

  fetchSurveyBelts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BeltActions.fetchSurveyBelts),
      switchMap((action) => {
        return this.httpClient
          .get<Belt[]>(
            `${environment.baseUrl}/belts/survey/${action.survey.id}`
          )
          .pipe(
            map((data) =>
              BeltActions.fetchBeltsSuccess({
                belts: data,
              })
            ),
            catchError((error) => of(BeltActions.fetchBeltsFailed(error)))
          );
      })
    )
  );

  fetchSyntheticMaterials$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loginSuccess, BeltActions.fetchSyntheticMaterials),
      switchMap((action) => {
        return this.httpClient
          .get<Belt[]>(`${environment.baseUrl}/belts/synthetic`)
          .pipe(
            map((syntheticMaterials) =>
              BeltActions.fetchSyntheticMaterialsSuccess({ syntheticMaterials })
            ),
            catchError((error) =>
              of(BeltActions.fetchSyntheticMaterialsFailed(error))
            )
          );
      })
    )
  );

  fetchModularMaterials$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loginSuccess, BeltActions.fetchModularMaterials),
      switchMap((action) => {
        return this.httpClient
          .get<Belt[]>(`${environment.baseUrl}/belts/modular/materials`)
          .pipe(
            map((modularMaterials) =>
              BeltActions.fetchModularMaterialsSuccess({ modularMaterials })
            ),
            catchError((error) =>
              of(BeltActions.fetchModularMaterialsFailed(error))
            )
          );
      })
    )
  );

  fetchModularTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loginSuccess, BeltActions.fetchModularTypes),
      switchMap((action) => {
        return this.httpClient
          .get<Belt[]>(`${environment.baseUrl}/belts/modular/types`)
          .pipe(
            map((modularTypes) =>
              BeltActions.fetchModularTypesSuccess({ modularTypes })
            ),
            catchError((error) =>
              of(BeltActions.fetchModularTypesFailed(error))
            )
          );
      })
    )
  );

  fetchModularColours$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loginSuccess, BeltActions.fetchModularColours),
      switchMap((action) => {
        return this.httpClient
          .get<Belt[]>(`${environment.baseUrl}/belts/modular/colours`)
          .pipe(
            map((modularColours) =>
              BeltActions.fetchModularColoursSuccess({ modularColours })
            ),
            catchError((error) =>
              of(BeltActions.fetchModularColoursFailed(error))
            )
          );
      })
    )
  );
}
