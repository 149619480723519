import { Injectable } from '@angular/core';

import { IAlert } from './alert.interface';

@Injectable()
export class AlertService {
  listener: IAlert;

  subscribeToAlerts(listener: IAlert) {
    this.listener = listener;
  }

  alertSuccess(text: String) {
    if (!this.listener) {
      return;
    }
    this.listener.onSuccessAlert(text);
  }

  alertError(text: String) {
    if (!this.listener) {
      return;
    }
    this.listener.onErrorAlert(text);
  }

  handleError(err: any) {
    console.log(err);
    let message = err?.error?.error?.message ?? '';
    if (!message) message = err?.error?.message ?? '';
    if (message) {
      this.listener.onErrorAlert(message);
    } else {
      this.listener.onErrorAlert(JSON.stringify(err));
    }
  }

  alertWarning(text: String) {
    if (!this.listener) {
      return;
    }
    this.listener.onWarningAlert(text);
  }

  alertInfo(text: String) {
    if (!this.listener) {
      return;
    }
    this.listener.onInfoAlert(text);
  }

  handleHttpError(err) {
    console.error('handleHttpError err', err);

    if (err.error && typeof err.error === 'string') {
      this.alertError(err.error);
    } else if (
      err.message &&
      typeof err.message === 'string' &&
      err.message === 'Invalid token specified'
    ) {
      this.alertError(
        'There is a new app release! Please log out and login again to get the latest features'
      );
    } else if (err.message && typeof err.message === 'string') {
      console.log(err.message);
    } else {
      console.log(err);
    }
  }
}
