import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import { CBeltAuthGuard } from '../guards/c-belt-auth.guard';
import { MsalTokenService } from '../services/c-belt/msal-token.service';

import { AppLayoutComponent } from '../components/app-layout/app-layout.component';

import { CBeltComponent } from './c-belt/c-belt.component';
import { ScanSensorComponent } from './c-belt/scan-sensor/scan-sensor.component';
import { ScanningComponent } from './c-belt/scanning/scanning/scanning.component';
import { CbeltListComponent } from './c-belt/installing/cbelt-list/cbelt-list.component';
import { AddSensorsComponent } from './c-belt/installing/add-sensors/add-sensors.component';
import { EditSensorComponent } from './c-belt/installing/edit-sensor/edit-sensor.component';
import { ScanningRfidComponent } from './c-belt/fitting/scanning-rfid/scanning-rfid.component';
import { SystemSetupComponent } from './c-belt/installing/system-setup/system-setup.component';
import { ConfigureWifiComponent } from './c-belt/installing/configure-wifi/configure-wifi.component';
import { InstallingHomeComponent } from './c-belt/installing/installing-home/installing-home.component';
import { NewGatewayDeviceComponent } from './c-belt/installing/new-gateway-device/new-gateway-device.component';
import { ConfiguringDeviceComponent } from './c-belt/installing/configuring-device/configuring-device.component';
import { CBeltConfigurationComponent } from './c-belt/fitting/c-belt-configuration/c-belt-configuration.component';
import { CBeltInstallationComponent } from './c-belt/installing/c-belt-installation/c-belt-installation.component';
import { AddSensorManuallyComponent } from './c-belt/installing/add-sensor-manually/add-sensor-manually.component';
import { PairConveyorReaderComponent } from './c-belt/installing/pair-conveyor-reader/pair-conveyor-reader.component';
import { SelectConfigurationComponent } from './c-belt/modifying/select-configuration/select-configuration.component';
import { SystemSetupSensorsComponent } from './c-belt/installing/system-setup-sensors/system-setup-sensors.component';
import { SensorConfigurationComponent } from './c-belt/installing/sensor-configuration/sensor-configuration.component';
import { GatewayConfigurationComponent } from './c-belt/installing/gateway-configuration/gateway-configuration.component';
import { SystemSetupPairRfidComponent } from './c-belt/installing/system-setup-pair-rfid/system-setup-pair-rfid.component';
import { PairConveyorAndBeltComponent } from './c-belt/installing/pair-conveyor-and-belt/pair-conveyor-and-belt.component';
import { ConfigurationOverviewComponent } from './c-belt/installing/configuration-overview/configuration-overview.component';
import { ConfiguringDeviceFourGComponent } from './c-belt/installing/configuring-device-four-g/configuring-device-four-g.component';
import { CalibrateConveyorReaderComponent } from './c-belt/installing/calibrate-conveyor-reader/calibrate-conveyor-reader.component';
import { PairConveyorReaderManuallyComponent } from './c-belt/installing/pair-conveyor-reader-manually/pair-conveyor-reader-manually.component';
import { ConveyorReaderConfigurationComponent } from './c-belt/installing/conveyor-reader-configuration/conveyor-reader-configuration.component';
import { SelectConfigurationPairRfidComponent } from './c-belt/modifying/select-configuration-pair-rfid/select-configuration-pair-rfid.component';
import { AddRfidManuallyComponent } from './c-belt/installing/add-rfid-manually/add-rfid-manually.component';

const guard = MsalTokenService.isMobileApp() ? CBeltAuthGuard : MsalGuard;

const routes: Routes = [
  {
    path: '',
    canActivate: [guard],
    canActivateChild: [guard],
    component: AppLayoutComponent,
    children: [
      {
        path: 'c-belt',
        component: CBeltComponent,
      },
      {
        path: 'scanning',
        component: ScanningComponent,
      },
      {
        path: 'scan-sensor',
        component: ScanSensorComponent,
      },
      {
        path: 'fitting',
        component: CBeltConfigurationComponent,
      },
      {
        path: 'fitting/scanning/:orderNumber',
        component: ScanningRfidComponent,
      },
      {
        path: 'fitting/scanning/:orderNumber/add-manually',
        component: AddRfidManuallyComponent,
      },
      {
        path: 'installing',
        component: InstallingHomeComponent,
      },
      {
        path: 'select-config',
        component: SelectConfigurationComponent,
      },
      {
        path: 'select-config-pair-rfid',
        component: SelectConfigurationPairRfidComponent,
      },
      {
        path: 'installing/:company_id',
        component: CBeltInstallationComponent,
      },
      {
        path: 'installing/:company_id/configuration-overview',
        component: ConfigurationOverviewComponent,
      },
      {
        path: 'installing/:company_id/gateway-configuration',
        component: GatewayConfigurationComponent,
      },
      {
        path: 'installing/:company_id/gateway-configuration/new-gateway-device',
        component: NewGatewayDeviceComponent,
      },
      {
        path: 'installing/:company_id/gateway-configuration/new-gateway-device/configure-wifi/configuring-device',
        component: ConfiguringDeviceComponent,
      },
      {
        path: 'installing/:company_id/gateway-configuration/new-gateway-device/configure-wifi',
        component: ConfigureWifiComponent,
      },
      {
        path: 'installing/:company_id/gateway-configuration/new-gateway-device/configure-wifi/configuring-device-four-g',
        component: ConfiguringDeviceFourGComponent,
      },
      {
        path: 'installing/:company_id/system-setup',
        component: SystemSetupComponent,
      },
      {
        path: 'installing/:company_id/conveyor-reader-configuration',
        component: ConveyorReaderConfigurationComponent,
      },
      {
        path: 'installing/:company_id/conveyor-reader-configuration/pair-conveyor-reader/:conveyorName',
        component: PairConveyorReaderComponent,
      },
      {
        path: 'installing/:company_id/conveyor-reader-configuration/pair-belt-and-conveyor/:conveyorName',
        component: PairConveyorAndBeltComponent,
      },
      {
        path: 'installing/:company_id/conveyor-reader-configuration/pair-conveyor-reader-manually/:conveyorName',
        component: PairConveyorReaderManuallyComponent,
      },
      {
        path: 'installing/:company_id/calibrate-conveyor',
        component: CalibrateConveyorReaderComponent,
      },
      {
        path: 'installing/:company_id/system-setup-sensors',
        component: SystemSetupSensorsComponent,
      },
      {
        path: 'installing/:company_id/system-setup-pair-rfid',
        component: SystemSetupPairRfidComponent,
      },
      {
        path: 'installing/:company_id/system-setup-pair-rfid/select-belt',
        component: CbeltListComponent,
      },
      {
        path: 'installing/:company_id/sensor-configuration',
        component: SensorConfigurationComponent,
      },
      {
        path: 'installing/:company_id/sensor-configuration/add-edit-sensors',
        component: AddSensorsComponent,
      },
      {
        path: 'installing/:company_id/sensor-configuration/add-edit-sensors/add-sensor-manually',
        component: AddSensorManuallyComponent,
      },
      {
        path: 'installing/:company_id/sensor-configuration/edit-sensor',
        component: EditSensorComponent,
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InstallationRoutingModule {}
