import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-configuring-device-four-g',
  templateUrl: './configuring-device-four-g.component.html',
  styleUrls: ['./configuring-device-four-g.component.scss'],
})
export class ConfiguringDeviceFourGComponent implements OnInit {
  companyId: string;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.companyId = params['company_id'];
    });
  }

  navToSteps() {
    this.router.navigate(['/installing', this.companyId]);
  }
}
