import { TranslateService } from "./../../../services/translate.service";
import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
	selector: "app-search-input",
	templateUrl: "./search-input.component.html",
	styleUrls: ["./search-input.component.scss"],
})
export class SearchInputComponent {
	@Input() searchText: string;
	@Output() searchTextChange: EventEmitter<any> = new EventEmitter<any>();

	constructor(public t: TranslateService) {}

	onTextInput() {
		this.searchTextChange.emit(this.searchText);
	}
}
