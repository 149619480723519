import { cloneDeep } from 'lodash';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Sensor } from '../../../../models/sensor.model';

import { ModalService } from '../../../../services/modal/modal.service';
import { SensorsService } from '../../../../services/c-belt/sensors.service';
import { AlertService } from './../../../../shared/services/alert/alert.service';
import { LoadingService } from './../../../../shared/services/loading/loading.service';

import { take } from 'rxjs';
import { Store } from '@ngrx/store';
import { ConfigurationState } from '../../../../state/installation/installation.state';
import * as InstallationActions from '../../../../state/installation/installation.actions';
import * as InstallationSelectors from '../../../../state/installation/installation.selector';

@Component({
  selector: 'app-edit-sensor',
  templateUrl: './edit-sensor.component.html',
  styleUrls: ['./edit-sensor.component.scss'],
})
export class EditSensorComponent implements OnInit {
  baseRoute: string;
  companyId: string;
  gatewayId: string;

  sensor: Sensor = new Sensor();
  sensorToPut: Sensor = new Sensor();

  current: ConfigurationState = null;

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private alertService: AlertService,
    private sensorService: SensorsService,
    private loadingService: LoadingService
  ) {}

  ngOnInit() {
    this.route.url.subscribe((route) => {
      this.baseRoute = route[0].path;
    });
    this.route.params.subscribe((params) => {
      this.companyId = params['company_id'];
      this.subscribeToSensor();
      this.subscribeToCurrentConfiguration();
    });
  }

  subscribeToSensor() {
    const select = InstallationSelectors.selectSensorToEdit();
    this.store
      .select(select)
      .pipe(take(1))
      .subscribe((sensor) => {
        this.sensor = cloneDeep(sensor);
      });
  }

  subscribeToCurrentConfiguration() {
    const select = InstallationSelectors.selectCurrentSummary();
    this.store
      .select(select)
      .pipe(take(1))
      .subscribe((current) => {
        this.current = cloneDeep(current);
      });
  }

  async updateSensor() {
    this.loadingService.show();
    try {
      this.sensorToPut.conveyorId = this.sensor.conveyorId;
      this.sensorToPut.aliasName = this.sensor.sensorNickname;
      this.sensorToPut.description = this.sensor.description;
      this.sensorToPut.role = this.sensor.role;
      await this.sensorService.updateSensor(this.sensorToPut, this.sensor.id);
      this.store.dispatch(InstallationActions.updateSensor({ sensor: this.sensorToPut }));
      this.alertService.alertSuccess('Sensor Updated Successfully.');
    } catch (err) {
      this.alertService.handleError(err);
    }
    this.loadingService.hide();
  }

  async deleteSensor() {
    this.loadingService.show();
    try {
      this.sensorToPut.conveyorId = null;
      this.sensorToPut.aliasName = this.sensor.sensorNickname;
      this.sensorToPut.description = this.sensor.description;
      this.sensorToPut.sid = this.sensor.sid.toString();
      if (this.sensorToPut.sid == '5') {
        this.sensorToPut.role = 'DriveDrum';
      }
      this.gatewayId = await this.sensorService.getGatewayIdBySensorId(this.sensor.id);
      await this.unMapSensorAndGateway();
      await this.sensorService.updateSensor(this.sensorToPut, this.sensor.id);
      this.store.dispatch(InstallationActions.deleteSensor({ sensor: this.sensor }));
      if (this.sensorToPut.sid == '1') {
        this.current.conveyorReader.readerId = null;
        this.store.dispatch(
          InstallationActions.updateConveyorReader({
            conveyorReader: this.current.conveyorReader,
          })
        );
      }
      this.router.navigate([this.baseRoute, this.companyId, 'sensor-configuration']);
    } catch (err) {
      this.alertService.handleError(err);
    }
    this.loadingService.hide();
  }

  async unMapSensorAndGateway() {
    await this.sensorService.unMapSensorAndGateway(this.sensor.id, this.gatewayId);
    this.alertService.alertSuccess('Sensor and Gateway Un-Mapping Successful!');
  }

  returnSid(): boolean {
    return this.sensor.sid == '5';
  }

  selectRole(role: string) {
    this.sensor.role = role;
  }

  returnSensorType(id: Number) {
    switch (id) {
      case 1:
        return 'Belt Speed';
      case 2:
        return 'Humidity';
      case 3:
        return 'Acceleration';
      case 4:
        return 'Temperature';
      case 5:
        return 'Drum Speed';
    }
  }

  async deleteAndNavToSensorConfiguration() {
    this.modalService.showDeleteModal({
      title: 'Delete Sensor',
      message: 'Are you sure you want to delete this sensor?',
      delete: () => this.deleteSensor(),
    });
  }

  async saveAndNavToSensorConfiguration() {
    await this.updateSensor();
    this.router.navigate([this.baseRoute, this.companyId, 'sensor-configuration']);
  }
}
