import { Component, Input } from '@angular/core';

import { Animations } from './../../../services/animations/animations';
import { TranslateService } from 'src/app/services/translate.service';

@Component({
  selector: 'app-meta-menu',
  templateUrl: './meta-menu.component.html',
  styleUrls: ['./meta-menu.component.scss'],
  animations: [Animations.fadeInOut()],
})
export class MetaMenuComponent {
  constructor(public t: TranslateService) {}
  isMenuOpen: boolean;

  @Input() symbol: string = 'more_vert';
  @Input() public menuItems: MenuItem[] = [];

  clickHandler(callback) {
    this.isMenuOpen = !this.isMenuOpen;
    callback();
  }
}

export type MenuItem = {
  symbol: string;
  title: string;
  onClick?: any;
};
