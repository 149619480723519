import { TranslateService } from './../../../../../services/translate.service';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Belt } from '../../../../../models/belt.model';
import { MenuItem } from '../../../../../shared/components/meta-menu/meta-menu.component';

@Component({
  selector: 'app-mobile-belt-item-card',
  templateUrl: './mobile-belt-item-card.component.html',
  styleUrl: './mobile-belt-item-card.component.scss'
})
export class MobileBeltItemCardComponent {
  @Input() belt: Belt;

  @Output() itemClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() copyClick: EventEmitter<Belt> = new EventEmitter<Belt>();
  @Output() removeClick: EventEmitter<Belt> = new EventEmitter<Belt>();

  public menuItems: MenuItem[] = [];

  constructor(public t: TranslateService){}

  ngOnInit() {
    this.menuItems = this.getBeltMenuItems(this.belt);
  }

  public hasValidImage(belt) {
    return belt.attachmentUrl || belt.image?.displayImage
  }
  
  public isBase64(belt: Belt) {
    if (typeof belt.image?.displayImage == 'string') {
      return belt.image?.displayImage.includes('base64');
    }
  }

  private getBeltMenuItems(belt: Belt) {
    const menuItems = [
      {
        symbol: 'content_copy',
        title: `${this.t.translate("_COPY_BELT")}`,
        onClick: () => this.copyClick.emit(belt)
      },
      {
        symbol: 'delete_forever',
        title: `${this.t.translate("_REMOVE")}`,
        onClick: () => this.removeClick.emit(belt)
      }
    ];
    return menuItems;
  }

  public onItemClick(itemDetails: {id: string, event}) {
    this.itemClick.emit(itemDetails);
  }

  public componentTranslations(synOrMod: string){
    if (synOrMod == "synthetic") {
		return `${this.t.translate("_SYNTHETIC")}`;
	} else if (synOrMod == "modular") {
		return `${this.t.translate("_MODULAR")}`;
	}
  }
}
