import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnInit,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { AppFormGroup } from '../../../utils/app-form-group';
import { TranslateService } from './../../../services/translate.service';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public model;
  @Output() public modelChange: EventEmitter<any> = new EventEmitter();

  @Input() public label: string;
  @Input() public type: string = 'text';
  @Input() public name: string = '';
  @Input() public placeholder: string = '';
  @Input() public autofocus = false;
  @Input() public form: AppFormGroup;

  public focus = false;

  public get formControl(): FormControl {
    return this.form.get(this.name) as FormControl;
  }

  private sub: Subscription;

  constructor(public t: TranslateService) {}

  public ngOnInit(): void {
    this.sub = this.formControl.valueChanges.subscribe((change) => {
      if (this.type === 'email') {
        if (this.formControl.value !== change.toLowerCase()) {
          this.formControl.setValue(change.toLowerCase());
          return;
        }
      }

      if (this.type === 'number') change = Number(change);

      this.modelChange.emit(change);
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.model) this.formControl.setValue(this.model);
  }

  public ngOnDestroy(): void {
    if (this.sub) this.sub.unsubscribe();
  }

  public isRequired(): boolean {
    if (this.formControl.validator) {
      const validator = this.formControl.validator({} as FormControl);
      if (validator && validator.required) return true;
    }
    return false;
  }

  public getError(): string {
    const errorType = Object.keys(this.formControl.errors)[0];
    switch (errorType) {
      case 'required':
        return this.t.translate('_REQUIRED');
      case 'email':
        return this.label
          ? `${this.label} is not valid`
          : `${this.t.translate('_INVALID')} ${this.t.translate('_EMAIL')}`;
      case 'pattern':
        return this.label
          ? `${this.label} ${this.t.translate('_INVALID')}`
          : this.t.translate('_INVALID');
      case 'matchOther':
        return `${this.t.translate('_PASSWORDS_DO_NOT_MATCH')}`;
      case 'min':
        return `${this.t.translate('_MINIMUM')} ${this.formControl.errors.min.min} `;
      case 'max':
        return `${this.t.translate('_MAXIMUM')} ${this.formControl.errors.max.max}`;
      case 'minlength':
        return `${this.t.translate('_MINIMUM_LENGTH')} ${
          this.formControl.errors.minlength.requiredLength
        }`;
      case 'maxlength':
        return `${this.t.translate('_MAXIMUM_LENGTH')} ${
          this.formControl.errors.maxlength.requiredLength
        }`;
      case 'whiteSpaceOnly':
        return `${this.t.translate('_CANNOT_BE_EMPTY')}`;
    }

    return errorType || '';
  }
}
