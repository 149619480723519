import { ConveyorReader } from 'src/app/models/conveyorReader.model';
import { Component, Input } from '@angular/core';
import { Conveyor } from 'src/app/models/conveyor.model';

@Component({
  selector: 'app-conveyor-validation',
  templateUrl: './conveyor-validation.component.html',
  styleUrl: './conveyor-validation.component.scss',
})
export class ConveyorValidationComponent {
  @Input() sensors: Conveyor;
  @Input() conveyor: Conveyor;
  @Input() conveyorReader: ConveyorReader;
  @Input() containsRfidReader: boolean;

  public complete(): boolean {
    if (this.containsRfidReader) {
      return (
        this.conveyor.machineId !== null &&
        this.conveyor.aliasName !== null &&
        this.conveyor.diameter !== null
      );
    } else {
      return (
        this.conveyor.machineId !== null &&
        this.conveyor.aliasName !== null &&
        this.conveyor.diameter !== null &&
        this.conveyor.idleDrumDiameter !== null
      );
    }
  }

  public incomplete(): boolean {
    if (this.containsRfidReader) {
      return (
        this.conveyor.machineId === null ||
        this.conveyor.aliasName === null ||
        this.conveyor.diameter === null
      );
    } else {
      return (
        this.conveyor.machineId === null ||
        this.conveyor.aliasName === null ||
        this.conveyor.diameter === null ||
        this.conveyor.idleDrumDiameter === null
      );
    }
  }

  public emptyOptionalField(value: string): boolean {
    return value === null || value === '' || value === undefined;
  }
}
