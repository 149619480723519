import { Injectable } from '@angular/core';
import { Observable, Subscriber, Subscription } from 'rxjs';

import { StorageService } from './storage/storage.service';
import { AmmscanApiService } from './api/ammscanApi.service';
import { AlertService } from '../shared/services/alert/alert.service';

import { Language } from '../models/language';
import { Translation } from '../models/translation';

export enum TranslateContext {
  common = 'common',
}

export const LanguageMap = {
  English: 'en' || 'gb',
  Nederlands: 'nl',
  Italiano: 'it',
  Deutsch: 'ger' || 'de',
  简体中国: 'ch',
  Español: 'es',
  Français: 'fr',
  Suomi: 'fn' || 'fi',
  Polski: 'pl',
  Česky: 'cz',
};

export const LanguageFlags = {
  en: 'gb',
  nl: 'nl',
  pl: 'pl',
  cz: 'cz',
  ger: 'de',
  fr: 'fr',
  fn: 'fi',
  SWE: 'se',
  TH: 'th',
  ESP: 'es',
};

@Injectable({ providedIn: 'root' })
export class TranslateService {
  private contextsFetched: TranslateContext[] = [];
  private translations: any = {};
  private language: Language;
  private languages: Language[] = [];
  public languageKey: string;

  private subscribers: Subscriber<Language[]>[] = [];
  private observable: Observable<Language[]>;

  constructor(
    private alertService: AlertService,
    private storageService: StorageService,
    private ammscanApiService: AmmscanApiService
  ) {
    this.init();

    this.observable = new Observable<Language[]>((sub) => {
      this.subscribers.push(sub);
      sub.next(this.languages);
    });
  }

  public async init() {
    try {
      this.languageKey = this.storageService.getLanguageKey();
      await this.fetchLanguages();

      if (!this.languageKey) {
        this.resetLanguage();
      }
    } catch (err) {
      console.log(err);
    }
  }

  public translate(key: string): string {
    if (!this.language) {
      return key;
    }

    const translation: Translation = this.translations[key + this.language.id];
    if (translation && translation.translation) {
      return translation.translation;
    }
    return key;
  }

  public async fetchTranslations(context: TranslateContext): Promise<void> {
    try {
      if (this.isContextFetched(context)) {
        return;
      }
      this.contextsFetched.push(context);
      const translations = await this.getTranslations(context);
      this.updateTranslations(translations);
    } catch (err) {
      this.alertService.handleHttpError(err);
    }
  }

  private isContextFetched(context: TranslateContext): boolean {
    return this.contextsFetched.includes(context);
  }

  async fetchCscLanguageByUser(): Promise<Language> {
    return await this.ammscanApiService.get('/translate/csc-language/', {});
  }

  private getTranslations(context: TranslateContext): Promise<Translation[]> {
    return this.ammscanApiService.get('/translate/translations/context/' + context, {});
  }

  public async fetchLanguageTranslations(language: Language): Promise<Translation[]> {
    return this.ammscanApiService.get('/translate/translations/language/' + language.id, {});
  }

  public async saveTranslations(translations: Translation[]): Promise<Translation[]> {
    translations = await this.ammscanApiService.post('/translate', translations, {});
    this.updateTranslations(translations);
    return translations;
  }

  private updateTranslations(translations: Translation[]) {
    for (const translation of translations) {
      this.translations[translation.key + translation.language_id] = translation;
    }
  }

  async resetLanguage() {
    try {
      await this.setLanguageBasedOnLanguageId(1);
    } catch (error) {
      this.alertService.handleHttpError(error);
    }
  }

  private async setLanguageBasedOnLanguageId(language_id: number) {
    const lang = this.languages.find((x) => x.id === language_id);
    this.setLanguage(lang);
    this.updateLanguagesObservable();
  }

  private async fetchLanguages() {
    this.languages = await this.getLanguages();
    this.language = this.languages.find((x) => x.key === this.languageKey);
    this.updateLanguagesObservable();
  }

  public getLanguages(): Promise<Language[]> {
    try {
      return this.ammscanApiService.get('/translate/language/', {});
    } catch (err) {
      throw err;
    }
  }

  public async saveLanguage(language: Language) {
    const exists = !!language.id;
    language = await this.ammscanApiService.post('/translate/language/', language, {});

    if (exists) {
      this.languages = this.languages.map((x) => (x.id === language.id ? language : x));
    } else {
      this.languages.push(language);
    }

    this.updateLanguagesObservable();
  }

  public setLanguage(language: Language) {
    this.language = this.deepCopy(language);
    this.languageKey = this.language.key;
    this.storageService.setLanguageKey(this.language.key);

    this.updateLanguagesObservable();
  }

  getLanguageKey() {
    return this.storageService.getLanguageKey();
  }

  public subscribeToLanguageChanges(callback: (languages: Language[]) => void): Subscription {
    return this.observable.subscribe(callback);
  }

  public unsubscribeFromLanguageChanges(subscription: Subscription) {
    if (subscription) {
      subscription.unsubscribe();
    }
    this.subscribers = this.subscribers.filter((x) => !x.closed);
  }

  private updateLanguagesObservable() {
    for (const subscriber of this.subscribers) {
      subscriber.next(this.deepCopy(this.languages));
    }
  }

  private deepCopy(value: any) {
    return JSON.parse(JSON.stringify(value));
  }

  formatTranslationKey(inputValue: string): string {
    return '_' + inputValue.toUpperCase().replaceAll(' ', '_');
  }
}
