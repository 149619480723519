import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SurveyState } from './survey.state';
import { selectAllCustomers } from '../customer';
import { selectAllBelts } from '../belt/belt.selector';

export const selectSurveys = createFeatureSelector<SurveyState>('survey');

export const selectAllSurveys = createSelector(
  selectSurveys,
  selectAllCustomers,
  selectAllBelts,
  (state: SurveyState, customers, belts) =>
    state.surveys
      .filter((survey) => !survey.deleted)
      .map((survey) => ({
        ...survey,
        customer: customers.find((customer) => customer.id == survey.customerId),
        belts: belts.filter((belt) => belt.surveyId == survey.id),
      }))
      .filter((survey) => !!survey.customer) // TODO: remove this when we have a solution for making surveys as deleted when de customers
);

export const selectSurveyById = (props: { id: string }) =>
  createSelector(selectAllSurveys, (surveys) => surveys.find((survey) => survey.id == props.id));

export function fetchSurveysSuccess(fetchSurveysSuccess: any) {
  throw new Error('Function not implemented.');
}
