import { Component, Input } from '@angular/core';
import { ConveyorReader } from 'src/app/models/conveyorReader.model';

@Component({
  selector: 'app-conveyor-reader-validation',
  templateUrl: './conveyor-reader-validation.component.html',
  styleUrl: './conveyor-reader-validation.component.scss',
})
export class ConveyorReaderValidationComponent {
  @Input() conveyorReader: ConveyorReader;
  @Input() containsRfidReader: boolean;

  public complete(): boolean {
    if (this.containsRfidReader) {
      return (
        this.conveyorReader.machineName !== null &&
        this.conveyorReader.conveyorName !== null &&
        this.conveyorReader.diameter !== null &&
        this.conveyorReader.readerId !== null
      );
    } else {
      return (
        this.conveyorReader.machineName !== null &&
        this.conveyorReader.conveyorName !== null &&
        this.conveyorReader.diameter !== null &&
        this.conveyorReader.idleDrumDiameter !== null
      );
    }
  }

  public incomplete(): boolean {
    if (this.containsRfidReader) {
      return (
        this.conveyorReader.machineName === null ||
        this.conveyorReader.conveyorName === null ||
        this.conveyorReader.diameter === null ||
        this.conveyorReader.readerId == null
      );
    } else {
      return (
        this.conveyorReader.machineName === null ||
        this.conveyorReader.conveyorName === null ||
        this.conveyorReader.diameter === null ||
        this.conveyorReader.idleDrumDiameter === null
      );
    }
  }

  public emptyOptionalField(value: string): boolean {
    return value === null || value === '' || value === undefined;
  }
}
