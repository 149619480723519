import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  templateUrl: 'confirm-modal.component.html'
})
export class ConfirmModalComponent {

  public options: ConfirmModalOptions;
  public hide: boolean = false;

  constructor(
    private bsModalRef: BsModalRef
  ) { }

  onConfirm() {
    this.options.confirm();
    this.close();
  }

  onReject() {
    if (this.options.reject) {
      this.options.reject();
    }
    this.close();
  }

  close() {
    this.bsModalRef.hide();
  }

  toggleHide() {
    this.hide = !this.hide;
  }
}


export class ConfirmModalOptions {
  title: string;
  message: string;
  confirm: () => void;
  reject?: () => void;
}
