import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Survey } from './../../../models/survey.model';

import { TranslateService } from './../../../services/translate.service';

@Component({
  selector: 'app-mobile-survey-list',
  templateUrl: './mobile-survey-list.component.html',
  styleUrl: './mobile-survey-list.component.scss',
})
export class MobileSurveyListComponent {
  @Input() surveys: Survey[] = [];
  @Output() addSurveyClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeSurveyClick: EventEmitter<any> = new EventEmitter<any>();

  paginatedSurveys: Survey[] = [];

  constructor(public t: TranslateService) {
    document.addEventListener('scroll', (event) => {
      const element = document.body;

      const scroll = window.scrollY;
      const scrollAvailable = element.scrollHeight - element.clientHeight;
      const available = 100 - (scroll / scrollAvailable) * 100;

      if (available < 20) this.onScrollDown();
    });
  }

  ngOnChanges(changes) {
    if (changes.surveys) {
      this.populateRows();
    }
  }

  private populateRows() {
    if (!this.surveys?.length) {
      this.paginatedSurveys = [];
      return;
    }

    const tableRows = Math.min(20, this.surveys.length);
    const displayedRows = Math.min(this.paginatedSurveys.length, this.surveys.length);
    const maxItems = Math.max(tableRows, displayedRows);

    const rows = [];
    for (let i = 0; i < maxItems; i++) {
      rows.push(this.surveys[i]);
    }
    this.paginatedSurveys = rows;
  }

  onScrollDown() {
    if (this.paginatedSurveys.length < this.surveys.length) {
      const endIndex = Math.min(this.paginatedSurveys.length + 20, this.surveys.length);
      for (let i = this.paginatedSurveys.length; i < endIndex; i++) {
        this.paginatedSurveys.push(this.surveys[i]);
      }
    }
  }

  onAddSurveyClick() {
    this.addSurveyClick.emit();
  }

  public createMenuItems(survey: Survey) {
    return [
      {
        title: `${this.t.translate('_REMOVE')}`,
        symbol: 'delete_forever',
        onClick: () => {
          this.removeSurveyClick.emit(survey);
        },
      },
    ];
  }
}
