import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TranslateService } from '../../../services/translate.service';

@Component({
  selector: 'app-filter-button',
  templateUrl: './filter-button.component.html',
  styleUrl: './filter-button.component.scss'
})
export class FilterButtonComponent {
  @Input() hasActiveFilter: boolean;
  @Input() filterCount: number;
  @Output() filterButtonClick: EventEmitter<any> = new EventEmitter<any>();

  onFilterButtonClick() {
    this.filterButtonClick.emit()
  }

  constructor(public t: TranslateService){}
}
