import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler } from '@angular/common/http';
import { HttpRequest, HttpInterceptor } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { AuthService } from '../services/auth/auth.service';
import { MsalScope, MsalTokenService } from '../services/c-belt/msal-token.service';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private msalTokenService: MsalTokenService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.getRequestToken(request)).pipe(
      switchMap((token) => {
        if (!token) return next.handle(request);

        return next.handle(this.addAuthTokenToRequest(request, token));
      })
    );
  }

  async getRequestToken(request: HttpRequest<any>) {
    const ammscanApiUrl = environment.baseUrl;
    const ammcareApiUrl = environment.ammcareBaseUrl;
    const cBeltApiUrl = environment.cBeltApi;

    let scope: MsalScope;
    if (request.url.includes(ammscanApiUrl)) scope = MsalScope.ammscan;
    if (request.url.includes(ammcareApiUrl)) scope = MsalScope.ammscan;
    if (request.url.includes(cBeltApiUrl)) scope = MsalScope.cBelt;

    if (!scope) return null;
    return this.msalTokenService.getToken(scope);
  }

  private addAuthTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`),
    });
  }
}
