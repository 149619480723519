import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { UtilService } from './../../../utils/util.service';
import { AuthService, User } from '../../../services/auth/auth.service';
import { MsalTokenService } from '../../../services/c-belt/msal-token.service';
import { ForageService } from './../../../services/forage/forage.service';
import { LanguageFlags, TranslateService } from './../../../services/translate.service';

import { Language } from './../../../models/language';

import { AuthRole } from '../../../models/userAzureDetails.model';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  @Input() isProduction: boolean;
  @Output() closeSidebar: EventEmitter<any> = new EventEmitter<any>();

  user: User;
  version: string = environment.version;

  currentLanguage: Language;
  languageSub: Subscription;

  constructor(
    private router: Router,
    private msalTokenService: MsalTokenService,
    private authService: AuthService,
    private forageService: ForageService,
    public t: TranslateService,
    public utils: UtilService
  ) {}

  async ngOnInit() {
    this.languageSub = this.t.subscribeToLanguageChanges((languages) => {
      languages = languages.map((language) => ({
        ...language,
        country_flag: LanguageFlags[language.key],
      }));
      const languageKey = this.t.getLanguageKey();
      this.currentLanguage = languages.find((lang) => lang.key == languageKey);
    });
  }

  public getUserEmail() {
    this.user = this.authService.getUser();
    return this.user?.email;
  }

  public isAdmin() {
    this.user = this.authService.getUser();
    if (!this.user) return false;
    return this.user.role == 'admin' || this.isSysAdmin();
  }

  public isSysAdmin(): boolean {
    if (!this.user) return false;
    const orgRoles = this.user.organisationRoles ?? {};
    return Object.values(orgRoles).some((roles) => roles.includes(AuthRole.SysAdmin));
  }

  public onClose() {
    this.closeSidebar.emit();
  }

  public navToRoute(route): void {
    this.onClose();
    this.router.navigate([route]);
  }

  public async logout() {
    await this.forageService.clearForage();
    await this.msalTokenService.logout();
    this.onClose();
  }

  public isMobileOperatingSystem() {
    return MsalTokenService.isMobileApp();
  }
}
