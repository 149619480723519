import { Action, createReducer, on } from '@ngrx/store';

import { SurveyState, initialState } from './survey.state';
import { StateUtils } from '../state-utils';

import * as SurveyActions from './survey.actions';
import * as UserActions from '../user/user.actions';

const surveyReducer = createReducer(
  initialState,

  on(SurveyActions.resetSurveyState, (state) => ({
    ...initialState,
  })),

  on(SurveyActions.fetchSurveys, (state) => ({
    ...state,
    loading: true,
  })),

  on(SurveyActions.fetchSurveysSuccess, (state, { surveys }) => {
    return {
      ...state,
      surveys: StateUtils.combineStateArr(state.surveys, surveys),
      loading: false,
      loadedAt: new Date(),
    };
  }),

  on(SurveyActions.fetchSurveysFailed, (state, { error }) => ({
    ...state,
    loading: false,
  })),

  on(SurveyActions.addSurveySuccess, (state, { survey }) => {
    return {
      ...state,
      surveys: StateUtils.combineStateArr(state.surveys, [survey]),
      loading: false,
      loadedAt: new Date(),
    };
  }),

  on(SurveyActions.setSurveysSuccess, (state, { surveys }) => {
    return {
      ...state,
      surveys,
      loading: false,
      loadedAt: new Date(),
    };
  }),

  on(SurveyActions.updateSurveysSuccess, (state, { surveys }) => {
    return {
      ...state,
      surveys: StateUtils.combineStateArr(state.surveys, surveys),
      loading: false,
      loadedAt: new Date(),
    };
  }),

  on(SurveyActions.removeSurveySuccess, (state, { survey }) => {
    return {
      ...state,
      surveys: StateUtils.combineStateArr(state.surveys, [survey]),
      loading: false,
      loadedAt: new Date(),
    };
  }),

  on(UserActions.logoutSuccess, () => {
    return initialState;
  })
);

export function reducer(state: SurveyState | undefined, action: Action) {
  return surveyReducer(state, action);
}
