import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Breadcrumb, BreadcrumbService } from '../../services/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrl: './breadcrumbs.component.scss'
})
export class BreadcrumbsComponent {
  @Input() latestBreadcrumb?: string;
  breadcrumbs: Breadcrumb[] = [];

  breadcrumbSub: Subscription;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private router: Router
  ) { }

  ngOnInit() {
    this.breadcrumbSub = this.breadcrumbService.subscribeToBreadcrumbChanges((breadcrumbs) => {
      this.breadcrumbs = breadcrumbs;
    });
  }

  ngOnDestroy() {
    if (this.breadcrumbSub) {
      this.breadcrumbSub.unsubscribe();
    }
  }

  navigateToBreadcrumb(breadcrumb: Breadcrumb) {
    if (this.breadcrumbs.length - 1 == this.breadcrumbs.indexOf(breadcrumb)) { return; }

    this.router.navigate(breadcrumb.routerLink, { queryParams: breadcrumb.queryParams });
    this.breadcrumbService.removeBreadcrumbsAfterBreadcrumb(breadcrumb);
  }

  clearBreadcrumbs() {
    this.breadcrumbService.setBreadcrumbs([]);
  }
}
