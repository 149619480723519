import { Survey } from '../../models/survey.model';
import { createAction, props } from '@ngrx/store';

export const resetSurveyState = createAction('Reset Survey State');

export const fetchSurveys = createAction('[Survey API] Fetch Surveys Initiated');

export const fetchSurveysSuccess = createAction(
  '[Survey API] Fetch Surveys Success',
  props<{ surveys: Array<Survey> }>()
);

export const fetchSurveysFailed = createAction(
  '[Survey API] Fetch Surveys Failed',
  props<{ error: any }>()
);

export const fetchSurvey = createAction(
  '[Survey API] Fetch Survey Initiated',
  props<{ id: string }>()
);

export const fetchSurveySuccess = createAction(
  '[Survey API] Fetch Survey Success',
  props<{ survey: Survey }>()
);

export const fetchSurveyFailed = createAction(
  '[Survey API] Fetch Survey Failed',
  props<{ error: any }>()
);

export const fetchSurveyCsv = createAction(
  '[Survey API] Fetch Survey Csv Initiated',
  props<{ surveyId: string }>()
);

export const fetchSurveyCsvSuccess = createAction(
  '[Survey API] Fetch Survey Csv Success',
  props<{ csv: any }>()
);

export const fetchSurveyCsvFailed = createAction(
  '[Survey API] Fetch Survey Csv Failed',
  props<{ error: any }>()
);

export const addSurveySuccess = createAction(
  '[Survey API] Add Survey Success',
  props<{ survey: Survey }>()
);

export const setSurveysSuccess = createAction(
  '[Survey API] Set Surveys Success',
  props<{ surveys: Survey[] }>()
);

export const updateSurveysSuccess = createAction(
  '[Survey API] Update Surveys Success',
  props<{ surveys: Survey[] }>()
);

export const removeSurveySuccess = createAction(
  '[Survey API] Remove Survey Success',
  props<{ survey: Survey }>()
);
