import { createAction, props } from "@ngrx/store";

import { Belt } from './../../models/belt.model';
import { Survey } from './../../models/survey.model';

export const resetBeltState = createAction(
  'Reset Belt State'
);

export const fetchBelts = createAction(
  '[Belt API] Fetch Belts Initiated'
);
export const fetchBeltsSuccess = createAction(
  '[Belt API] Fetch Belts Success',
  props<{ belts: Array<Belt> }>()
);
export const fetchBeltsFailed = createAction(
  '[Belt API] Fetch Belts Failed',
  props<{ error: any }>()
);

export const fetchSyntheticMaterials = createAction(
  '[Belt API] Fetch Synthetic Materials Initiated'
);
export const fetchSyntheticMaterialsSuccess = createAction(
  '[Belt API] Fetch Synthetic Materials Success',
  props<{ syntheticMaterials: Array<any> }>()
);
export const fetchSyntheticMaterialsFailed = createAction(
  '[Belt API] Fetch Synthetic Materials Failed',
  props<{ error: any }>()
);

export const fetchModularMaterials = createAction(
  '[Belt API] Fetch Modular Materials Initiated'
);
export const fetchModularMaterialsSuccess = createAction(
  '[Belt API] Fetch Modular Materials Success',
  props<{ modularMaterials: Array<any> }>()
);
export const fetchModularMaterialsFailed = createAction(
  '[Belt API] Fetch Modular Materials Failed',
  props<{ error: any }>()
);

export const fetchModularTypes = createAction(
  '[Belt API] Fetch Modular Types Initiated'
);
export const fetchModularTypesSuccess = createAction(
  '[Belt API] Fetch Modular Types Success',
  props<{ modularTypes: Array<any> }>()
);
export const fetchModularTypesFailed = createAction(
  '[Belt API] Fetch Modular Types Failed',
  props<{ error: any }>()
);

export const fetchModularColours = createAction(
  '[Belt API] Fetch Modular Colours Initiated'
);
export const fetchModularColoursSuccess = createAction(
  '[Belt API] Fetch Modular Colours Success',
  props<{ modularColours: Array<any> }>()
);
export const fetchModularColoursFailed = createAction(
  '[Belt API] Fetch Modular Colours Failed',
  props<{ error: any }>()
);

export const fetchBeltById = createAction(
  '[Belt API] Fetch Belt Initiated',
  props<{ id: string }>()
);
export const fetchBeltSuccess = createAction(
  '[Belt API] Fetch Belt Success',
  props<{ belt: Belt }>()
);
export const fetchBeltFailed = createAction(
  '[Belt API] Fetch Belt Failed',
  props<{ error: any }>()
);

export const setBeltsSuccess = createAction(
  '[Belt API] Set Belt Success',
  props<{ belts: Belt[] }>()
);

export const updateBeltsSuccess = createAction(
  '[Belt API] Update Belts Success',
  props<{ belts: Belt[] }>()
);

export const addBeltsSuccess = createAction(
  '[Belt API] Add Belts Success',
  props<{ belts: Belt[] }>()
);
export const uploadBeltImageSuccess = createAction(
  '[Belt API] Upload Belt Image Success',
  props<{ beltId: string, attachmentUrl: string }>()
);
export const removeBeltImageSuccess = createAction(
  '[Belt API] Remove Belt Image Success',
  props<{ beltId: string }>()
);

export const addBeltSuccess = createAction(
  '[Belt API] Add Belt Success',
  props<{ belt: Belt }>()
);
export const updateBeltSuccess = createAction(
  '[Belt API] Update Belt Success',
  props<{ belt: Belt }>()
);
export const removeBeltSuccess = createAction(
  '[Belt API] Remove Belt Success',
  props<{ belt: Belt }>()
);

export const fetchSurveyBelts = createAction(
  '[Capsule] Fetch Survey Belts',
  props<{ survey: Survey }>()
);
