import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BreadcrumbService } from '../../services/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-desktop-header',
  templateUrl: './desktop-header.component.html',
  styleUrls: ['./desktop-header.component.scss'],
})
export class DesktopHeaderComponent {
  @Input() header: string;
  @Input() hasBottomPadding: boolean = true;
  @Input() showBackButton: boolean = true;
  @Output() back: EventEmitter<any> = new EventEmitter();

  constructor(private breadcrumbService: BreadcrumbService) {}

  onBackClick() {
    this.breadcrumbService.popBreadcrumb();
  }
}
