export class UserAzureDetails {
  email: string;
  organizations: Organization[] = [];
  permissions: Permission[] = [];
  authRoles: AuthOrgRole[] = [];
}

export class Organization {
  id: string = '';
  name: string = '';
  customerNumber: string = '';
  salesLocationCode: string = '';
  organizationType: string = '';
  parentOrganizationId: string = '';
}

export class Permission {
  orgId: string = '';
  orgName: string = '';
  permissionId: string = '';
  permissionArea: string = '';
  permissionName: string = '';
}

export class AuthOrgRole {
  orgId: string = '';
  orgName: string = '';
  roleId: string = '';
  roleName: string = '';
}

export enum AuthRole {
  CustomerUser = 'Customer_User',
  CustomerAdmin = 'Customer_Admin',
  CustomerUserAdmin = 'Customer_User_Admin',
  SalesUser = 'Sales_User',
  SysAdmin = 'Sys_Admin',
}
