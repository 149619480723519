import { Injectable } from '@angular/core';

import { MachineImport } from '../../models/machine.model';

import { AmmscanApiService } from '../api/ammscanApi.service';

@Injectable()
export class CustomerService {
  constructor(private ammscanApiService: AmmscanApiService) {}

  fetchAllCustomers(): Promise<any> {
    return this.ammscanApiService.get('/customers');
  }

  fetchCustomerAmmcareBelts(customerId: string): Promise<MachineImport[]> {
    return this.ammscanApiService.get(`/customers/ammcare-belts/${customerId}`);
  }
}
