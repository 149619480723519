import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { Customer } from '../../../../models/customer.model';

import { StateService } from '../../../../services/c-belt/state.service';
import { LoadingService } from './../../../../shared/services/loading/loading.service';
import { OrganizationsService } from '../../../../services/c-belt/organizations.service';

import { Store } from '@ngrx/store';
import * as InstallationActions from '../../../../state/installation/installation.actions';

@Component({
  selector: 'app-select-customer',
  templateUrl: './select-customer.component.html',
  styleUrls: ['./select-customer.component.scss'],
})
export class SelectCustomerComponent implements OnInit {
  searchText: string = '';
  customers: Customer[] = [];

  constructor(
    private store: Store,
    private router: Router,
    private stateService: StateService,
    private loadingService: LoadingService,
    private organizationsService: OrganizationsService
  ) {}

  ngOnInit(): void {
    this.fetchOrganizations();
  }

  async fetchOrganizations() {
    this.loadingService.show();
    try {
      this.customers = await this.organizationsService.getOrganizations();
    } catch (err) {
      this.router.navigate(['/']);
    }
    this.loadingService.hide();
  }

  async filterClients() {
    if (this.searchText == '' || !this.customers.length) {
      await this.fetchOrganizations();
    }

    this.customers = this.customers.filter((x) =>
      x.customer_name.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

  navToHome() {
    this.router.navigate(['c-belt']);
  }

  navTo(route: string, customer: Customer) {
    this.stateService.subscribeToCurrentAndSave();
    this.store.dispatch(InstallationActions.updateCompany({ customer }));
    this.router.navigate([this.router.url, route]);
  }
}
