import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Csc } from './../../models/csc.model';
import { environment } from './../../../environments/environment';

import * as CscActions from '../csc/csc.actions';
import * as UserActions from '../user/user.actions';

@Injectable()
export class CscEffects {
  constructor(private actions$: Actions<any>, private httpClient: HttpClient) {}

  fetchCscs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CscActions.fetchCscs, UserActions.loginSuccess),

      switchMap(() => {
        return this.httpClient.get<Csc[]>(environment.baseUrl + '/csc').pipe(
          map((cscs) => {
            return CscActions.fetchCscsSuccess({ cscs });
          }),
          catchError((error) => {
            return of(CscActions.fetchCscsFailed({ error }));
          })
        );
      })
    )
  );
}
