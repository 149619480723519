import { trigger, state, style, transition, animate } from '@angular/animations';

export class Animations {
  static flyInOut() {
    return trigger('flyInOut', [
      transition('void => *', [style({ transform: 'translateX(100%)' }), animate('200ms')]),
      transition('* => void', [animate('200ms', style({ transform: 'translateX(100%)' }))]),
    ]);
  }

  static fadeInSlideOut() {
    return trigger('fadeInSlideOut', [
      transition('void => *', [
        style({ transform: 'translateX(10%)', opacity: 0 }),
        animate('300ms'),
      ]),
      transition('* => void', [
        animate('300ms', style({ transform: 'translateX(10%)', opacity: 0 })),
      ]),
    ]);
  }

  static slideInFadeOut() {
    return trigger('slideInFadeOut', [
      transition('void => *', [style({ transform: 'translateX(100%)' }), animate('200ms')]),
      transition('* => void', [animate('300ms', style({ opacity: 0 }))]),
    ]);
  }

  static slideInOut() {
    return trigger('slideInOut', [
      transition('void => *', [style({ transform: 'translateX(-100%)' }), animate('200ms')]),
      transition('* => void', [animate('200ms', style({ transform: 'translateX(-100%)' }))]),
    ]);
  }

  static fadeInOut() {
    return trigger('fadeInOut', [
      transition('void => *', [style({ opacity: 0 }), animate('300ms')]),
      transition('* => void', [animate('300ms', style({ opacity: 0 }))]),
    ]);
  }
}
