import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable()
export class StorageService {
  private static LANGUAGE_KEY: string = 'LANGUAGE_KEY';
  private static IS_AUTO_SYNC: string = 'IS_AUTO_SYNC';

  private isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  public getLanguageKey(): string {
    return localStorage.getItem(StorageService.LANGUAGE_KEY);
  }

  public setLanguageKey(languageKey: string) {
    localStorage.setItem(StorageService.LANGUAGE_KEY, languageKey);
  }

  getIsAutoSync(): boolean {
    return JSON.parse(localStorage.getItem(StorageService.IS_AUTO_SYNC));
  }

  setIsAutoSync(isAutoSync: boolean) {
    localStorage.setItem(StorageService.IS_AUTO_SYNC, isAutoSync.toString());
  }

  private toObject(string) {
    if (string) {
      return JSON.parse(string);
    } else {
      return null;
    }
  }

  clear() {
    localStorage.clear();
  }
}
