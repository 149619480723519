import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { setTheme } from 'ngx-bootstrap/utils';

import { environment } from './../environments/environment';
import { appInit } from './state/core.actions';

import { SyncService } from './services/sync/sync.service';
import { AuthService } from './services/auth/auth.service';
import { ForageService } from './services/forage/forage.service';
import { LoadingService } from './shared/services/loading/loading.service';
import { MsalTokenService, MsalScope } from './services/c-belt/msal-token.service';
import { TranslateContext, TranslateService } from './services/translate.service';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  loading: boolean = false;
  initializingProgressMessage: string = '';

  constructor(
    private store: Store,
    private router: Router,
    private swUpdate: SwUpdate,
    private t: TranslateService,
    private syncService: SyncService,
    private authService: AuthService,
    private forageService: ForageService,
    private loadingService: LoadingService,
    private msalTokenService: MsalTokenService,
  ) {
    setTheme('bs5');
    this.addClarityTrackingCodeToHead();
  }

  async ngOnInit(): Promise<void> {
    this.clearOldCachesAndCheckForUpdates();

    if (window?.location.href.includes('logout')) return;

    this.loadingService.show();
    this.loading = true;

    try {
      this.initializingProgressMessage = 'Initializing...';
      await this.msalTokenService.init(MsalScope.ammscan);
      this.initializingProgressMessage = 'Authenticating...';
      await this.msalTokenService.authenticate();

      this.initializingProgressMessage = 'Fetching Translations...';
      await this.t.init();
      await this.t.fetchTranslations(TranslateContext.common);

      this.store.dispatch(appInit());
      this.initializingProgressMessage = 'Initializing Offline Services...';
      await this.forageService.init();
      await this.syncService.init();

      if (MsalTokenService.isMobileApp()) {
        const user = this.authService.getUser();
        if (user) this.router.navigate(['surveys']);
      }
    } catch (error) {
      console.error('Error during MSAL initialization:', error);
    }

    this.loading = false;
    this.loadingService.hide();
  }

  private addClarityTrackingCodeToHead() {
    const head = document.getElementsByTagName('head')[0];
    const isProduction = environment.production;

    if (isProduction) {
      const script = document.createElement('script');
      script.innerHTML = `
      (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "j1wofi2ghz");
      `;
      head.insertBefore(script, head.lastChild);
    }
  }

  private clearOldCachesAndCheckForUpdates(): void {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => {
          registration.unregister();
        });
      });

      caches.keys().then((cacheNames) => {
        return Promise.all(cacheNames.map((cacheName) => caches.delete(cacheName)));
      });
    }

    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe((event) => {
        if (event.type === 'VERSION_READY') {
          this.swUpdate.activateUpdate().then(() => location.reload());
        }
      });
    }
  }
}
