import { Injectable } from '@angular/core';

import { CBeltMachine } from 'src/app/models/cBeltMachine.model';

import { CBeltApiService } from '../api/cBeltApi.service';

@Injectable({ providedIn: 'root' })
export class MachinesService {
  constructor(private cBeltApiService: CBeltApiService) {}

  async getMachines(): Promise<CBeltMachine[]> {
    const options = { params: { pageSize: 1000 } };
    const response = await this.cBeltApiService.get('/machines', options);
    return response['result']['items'].map((x) => CBeltMachine.fromJson(x));
  }

  async getMachineById(id: string): Promise<CBeltMachine> {
    const response = await this.cBeltApiService.get(`/machines/${id}`);
    return CBeltMachine.fromJson(response['result']);
  }

  async addMachine(machine: CBeltMachine): Promise<void> {
    return await this.cBeltApiService.post('/machines', machine);
  }

  async updateMachine(machine: CBeltMachine): Promise<void> {
    return await this.cBeltApiService.put(`/machines/${machine.id}`, machine);
  }
}
