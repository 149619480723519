import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrl: './mobile-header.component.scss',
})
export class MobileHeaderComponent {
  @Input() routerLinkUrl;
  @Input() showDefaultBackButton: boolean = false;
  @Input() header: string;
  @Input() addSpacer: boolean = false;

  @Output() backButtonClicked = new EventEmitter();

  emitBackPressed() {
    this.backButtonClicked.emit();
  }

  onBackPressed() {
    window.history.back();
    this.emitBackPressed();
  }
}
