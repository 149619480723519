import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

import * as CustomerActions from './customer.actions';

import { environment } from '../../../environments/environment';
import { Customer } from '../../models/customer.model';

@Injectable()
export class CustomerEffects {
  constructor(private actions$: Actions<any>, private httpClient: HttpClient) {}

  fetchCustomers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerActions.fetchCustomers),

      switchMap(() => {
        return this.httpClient.get<Customer[]>(`${environment.baseUrl}/customers`).pipe(
          map((customers) => {
            if (!customers) {
              return CustomerActions.fetchCustomersFailed({
                error: new Error('Something went wrong.'),
              });
            }

            return CustomerActions.fetchCustomersSuccess({ customers });
          }),
          catchError((error) => of(CustomerActions.fetchCustomersFailed({ error })))
        );
      })
    )
  );
}
