import { BaseState } from '../base.state';

import { CBelt } from 'src/app/models/cBelt.model';
import { Sensor } from 'src/app/models/sensor.model';
import { Gateway } from 'src/app/models/gateway.model';
import { Customer } from 'src/app/models/customer.model';
import { Conveyor } from 'src/app/models/conveyor.model';
import { CBeltMachine } from 'src/app/models/cBeltMachine.model';
import { ConveyorReader } from 'src/app/models/conveyorReader.model';

export class InstallationState extends BaseState {
  current: ConfigurationState;
}

export class ConfigurationState extends BaseState {
  company: Customer;
  belts: CBelt[];
  belt: CBelt;
  conveyors: Conveyor[];
  conveyor: Conveyor;
  gateways: Gateway[];
  gateway: Gateway;
  conveyorReaders: ConveyorReader[];
  conveyorReader: ConveyorReader;
  machines: CBeltMachine[];
  machine: CBeltMachine;
  readerCalibrated: boolean;
  sensors: Sensor[];
  sensorsForConveyor: Sensor[];
  sensor: Sensor;
  newSensorId: any;
  editing: boolean;
  containsRfidReader: boolean;
  isRndUser: boolean;
  pairingRfid: boolean;

  static fromJson(json) {
    json = json ?? {};

    const config = new ConfigurationState();
    config.company = Customer.fromJson(json.company ?? {});
    config.belts = json.belts ? json.belts.map((x) => CBelt.fromJson(x)) : [];
    config.belt = CBelt.fromJson(json.belt ?? {});
    config.conveyors = json.conveyors ? json.conveyors.map((x) => Conveyor.fromJson(x)) : [];
    config.conveyor = Conveyor.fromJson(json.conveyor ?? {});
    config.gateways = json.gateways ? json.gateways.map((x) => Gateway.fromJson(x)) : [];
    config.gateway = Gateway.fromJson(json.gateway ?? {});
    config.conveyorReaders = json.conveyorReaders
      ? json.conveyorReaders.map((x) => ConveyorReader.fromJson(x))
      : [];
    config.conveyorReader = ConveyorReader.fromJson(json.conveyorReader ?? {});
    config.machines = json.machines ? json.machines.map((x) => CBeltMachine.fromJson(x)) : [];
    config.machine = CBeltMachine.fromJson(json.machine ?? {});
    config.readerCalibrated = json.readerCalibrated ?? false;
    config.sensors = json.sensors ? json.sensors.map((x) => Sensor.fromJson(x)) : [];
    config.sensorsForConveyor = json.sensorsForConveyor
      ? json.sensorsForConveyor.map((x) => Sensor.fromJson(x))
      : [];
    config.sensor = Sensor.fromJson(json.sensor ?? {});
    config.newSensorId = json.newSensorId ?? null;
    config.editing = json.editing ?? false;
    config.containsRfidReader = json.containsRfidReader ?? false;
    config.isRndUser = json.isRndUser ?? false;
    config.pairingRfid = json.pairingRfid ?? false;

    return config;
  }
}

export const initialState = {
  current: {
    company: null,
    belts: [],
    belt: null,
    conveyors: [],
    conveyor: null,
    gateways: [],
    gateway: null,
    conveyorReaders: [],
    conveyorReader: null,
    machines: [],
    machine: null,
    readerCalibrated: false,
    sensors: [],
    sensorsForConveyor: [],
    sensor: null,
    newSensorId: null,
    editing: false,
    containsRfidReader: false,
    isRndUser: false,
    pairingRfid: false,
  },
};
