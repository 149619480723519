import { Belt } from './belt.model';
import { Customer } from './customer.model';
import { Model } from './../state/state-utils';

export class Survey implements Model {
  id: string;
  created: Date = new Date();
  updated: Date = new Date();
  createdBy: string;
  updatedBy: string;

  site: any;
  belts: Belt[] = [];
  customerId: string;
  deleted: boolean;

  customer_name: string;
  customer?: Customer;
  user?: any;

  dirty: boolean = true;
}
