import { from } from 'rxjs';
export class Gateway {
  id: string;

  gatewayDeviceId: string;
  networkName: string;
  devicePassword: string;
  ipAddress: string;
  networkMask: string;
  gatewayAddress: string;
  dns: string;
  wifiType: string;

  static fromJson(json: any): Gateway {
    let gateway = new Gateway();
    gateway.id = json.id;
    gateway.gatewayDeviceId = json.gatewayDeviceId;
    gateway.networkName = json.networkName;
    gateway.devicePassword = json.devicePassword;
    gateway.ipAddress = json.ipAddress;
    gateway.networkMask = json.networkMask;
    gateway.gatewayAddress = json.gatewayAddress;
    gateway.dns = json.dns;
    gateway.wifiType = json.wifiType;
    return gateway;
  }
}
