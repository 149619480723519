import { SafeResourceUrl } from '@angular/platform-browser';

export class Image {
  displayImage: string | SafeResourceUrl;
  file: File;
  isNew: boolean;

  constructor() {
    this.displayImage = null;
    this.file = null;
    this.isNew = true;
  }

  static fromUrl(url: string): Image {
    const image = new Image();
    image.displayImage = url;
    image.isNew = false;
    image.file = null;
    return image;
  }
}
