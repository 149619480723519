import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export interface ApiOptions {
  params?: any;
}

@Injectable()
export class ApiService {
  constructor(private http: HttpClient) {}

  public get<T>(url: string, options: ApiOptions): Promise<T> {
    return new Promise((resolve, reject) => {
      this.http.get(url, { params: options.params }).subscribe({
        next: (res) => resolve(res as T),
        error: (err) => reject(err),
      });
    });
  }

  public post<T>(url: string, body: any, options: ApiOptions): Promise<T> {
    return new Promise((resolve, reject) => {
      this.http.post(url, body, { params: options.params }).subscribe({
        next: (res) => resolve(res as T),
        error: (err) => reject(err),
      });
    });
  }

  public put<T>(url: string, body: any, options: ApiOptions): Promise<T> {
    return new Promise((resolve, reject) => {
      this.http.put(url, body, { params: options.params }).subscribe({
        next: (res) => resolve(res as T),
        error: (err) => reject(err),
      });
    });
  }

  public delete<T>(url: string, options: ApiOptions): Promise<T> {
    return new Promise((resolve, reject) => {
      this.http.delete(url, { params: options.params }).subscribe({
        next: (res) => resolve(res as T),
        error: (err) => reject(err),
      });
    });
  }
}
