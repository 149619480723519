import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { Image } from '../../../../app/shared/components/image-input/models/image';
import { AlertService } from '../../services/alert/alert.service';
import { TranslateService } from '../../../services/translate.service';

import { NgxImageCompressService, DOC_ORIENTATION } from 'ngx-image-compress';

@Component({
  selector: 'image-input',
  templateUrl: './image-input.component.html',
  styleUrls: ['./image-input.component.scss'],
})
export class ImageInputComponent {
  @Input() image: Image;
  @Output() imageChange: EventEmitter<Image> = new EventEmitter();
  @Output() change: EventEmitter<Image> = new EventEmitter();

  @Input() size: number = 325;

  @Input() error = '';
  @Input() maxKb: number;
  @Input() label: string;

  constructor(
    private sanitizer: DomSanitizer,
    private alertService: AlertService,
    private imageCompress: NgxImageCompressService,
    public t: TranslateService
  ) {}

  onFileSelected(event) {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];

      if (this.maxKb && file.size / 1000 > this.maxKb) {
        this.alertService.alertError('This image is too large, Max Size is ' + this.maxKb + 'KB');
        event.target.value = '';
        return;
      }

      this.imageCompress
        .compressFile(URL.createObjectURL(file), DOC_ORIENTATION.Up, 30, 80)
        .then((compressedImage) => {
          const byteString = atob(compressedImage.split(',')[1]);
          const arrayBuffer = new ArrayBuffer(byteString.length);
          const intArray = new Uint8Array(arrayBuffer);
          for (let i = 0; i < byteString.length; i++) {
            intArray[i] = byteString.charCodeAt(i);
          }
          const compressedBlob = new Blob([intArray], { type: file.type });

          const compressedFile = new File([compressedBlob], file.name, { type: file.type });

          const reader = new FileReader();
          reader.onload = (e: any) => {
            const display = this.sanitizer.bypassSecurityTrustResourceUrl(e.target.result);

            this.image = {
              file: compressedFile,
              displayImage: display,
              isNew: true,
            };
            this.imageChange.emit(this.image);
            this.change.emit(this.image);
          };

          try {
            reader.readAsDataURL(compressedFile);
            event.target.value = '';
          } catch (err) {
            this.alertService.alertError(JSON.stringify(err));
            console.log(err);
          }
        });
    }
    event.stopPropagation();
  }

  public getIconSize() {
    let size;
    if (this.size < 30) {
      size = 30;
    } else if (this.size > 72) {
      size = 72;
    } else {
      size = this.size;
    }
    return size - 10;
  }

  clear() {
    this.image = new Image();
    this.imageChange.emit(this.image);
    this.change.emit(this.image);
  }

  async download() {
    const downloadLink = document.createElement('a');

    if (!this.image.file) {
      const response = await fetch(this.image.displayImage.toString(), {
        headers: new Headers({
          Origin: location.origin,
        }),
        mode: 'cors',
      });
      const imageBlob = await response.blob();
      downloadLink.href = window.URL.createObjectURL(imageBlob);
    } else {
      downloadLink.href = window.URL.createObjectURL(this.image.file);
    }

    downloadLink.target = '_blank';
    downloadLink.setAttribute('download', 'Image');

    document.body.appendChild(downloadLink);
    downloadLink.click();
  }
}
