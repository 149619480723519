import { Component, Input } from '@angular/core';
import { Sensor } from 'src/app/models/sensor.model';

@Component({
  selector: 'app-sensor-validation',
  templateUrl: './sensor-validation.component.html',
  styleUrl: './sensor-validation.component.scss',
})
export class SensorValidationComponent {
  @Input() sensors: Sensor[];
  @Input() containsRfidReader: boolean;

  public complete(sensor: Sensor): boolean {
    return (
      sensor.conveyorId !== null && sensor.sid !== null && sensor.edid !== null
    );
  }

  public incomplete(sensor: Sensor): boolean {
    return (
      sensor.conveyorId === null || sensor.sid === null || sensor.edid === null
    );
  }

  public emptyOptionalField(value: string): boolean {
    return value === null || value === '' || value === undefined;
  }

  returnSensorType(id: Number) {
    switch (id) {
      case 1:
        return 'Belt Speed';
      case 2:
        return 'Humidity';
      case 3:
        return 'Acceleration';
      case 4:
        return 'Temperature';
      case 5:
        return 'Drum Speed';
      default:
        return 'Unknown Sensor Type';
    }
  }
}
