import { cloneDeep } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { Sensor } from '../../../../models/sensor.model';
import { Conveyor } from '../../../../models/conveyor.model';
import { ConveyorReader } from '../../../../models/conveyorReader.model';

import { StateService } from '../../../../services/c-belt/state.service';
import { SensorsService } from '../../../../services/c-belt/sensors.service';
import { AlertService } from '../../../../shared/services/alert/alert.service';
import { LoadingService } from '../../../../shared/services/loading/loading.service';

import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ConfigurationState } from '../../../../state/installation/installation.state';
import * as InstallationActions from '../../../../state/installation/installation.actions';
import * as InstallationSelectors from '../../../../state/installation/installation.selector';

@Component({
  selector: 'app-pair-conveyor-reader-manually',
  templateUrl: './pair-conveyor-reader-manually.component.html',
  styleUrls: ['./pair-conveyor-reader-manually.component.scss'],
})
export class PairConveyorReaderManuallyComponent implements OnInit, OnDestroy {
  baseRoute: string;
  companyId: string;
  conveyorId: string;
  rfidReaderId: Number;
  conveyor: Conveyor = null;
  sensorToPut: Sensor = new Sensor();
  current: ConfigurationState = null;
  conveyorReader: ConveyorReader = new ConveyorReader();

  private currentSub: Subscription;
  private conveyorSub: Subscription;
  private conveyorReaderSub: Subscription;

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private stateService: StateService,
    private sensorService: SensorsService,
    private loadingService: LoadingService
  ) {}

  ngOnInit() {
    this.route.url.subscribe((route) => {
      this.baseRoute = route[0].path;
    });
    this.route.params.subscribe((params) => {
      this.companyId = params['company_id'];
      this.subscribeToCurrentConfiguration();
    });
  }

  ngOnDestroy(): void {
    if (this.currentSub) this.currentSub.unsubscribe();
    if (this.conveyorSub) this.conveyorSub.unsubscribe();
    if (this.conveyorReaderSub) this.conveyorReaderSub.unsubscribe();
  }

  subscribeToCurrentConfiguration() {
    const select = InstallationSelectors.selectCurrentSummary();
    this.currentSub = this.store.select(select).subscribe((current) => {
      this.current = cloneDeep(current);
      this.conveyor = this.current.conveyor;
      this.conveyorId = this.current.conveyor.id;
      this.conveyorReader = this.current.conveyorReader;

      if (this.current.newSensorId) {
        this.rfidReaderId = this.current.newSensorId;
      }
    });
  }

  navToPairReader() {
    this.router.navigate([
      this.baseRoute,
      this.companyId,
      'conveyor-reader-configuration',
      'pair-conveyor-reader',
      this.conveyor.aliasName,
    ]);
  }

  async navToAddConveyorReader() {
    if (!this.rfidReaderId) {
      this.alertService.alertError('Please add the Reader ID');
      return;
    }
    this.conveyorReader.readerId = this.rfidReaderId.toString();
    this.store.dispatch(
      InstallationActions.updateConveyorReader({
        conveyorReader: this.conveyorReader,
      })
    );

    await this.fetchSensorsByEdid();
    let sensor = await this.updateSensor(this.sensorToPut);
    await this.mapSensorAndGateway(sensor);
    this.updateSensorId();
    if (!sensor) {
      this.alertService.alertInfo('Invalid Reader ID!');
      return;
    }

    this.stateService.subscribeToCurrentAndSave();
    this.router.navigate([this.baseRoute, this.companyId]);
  }

  async mapSensorAndGateway(sensor: Sensor) {
    await this.sensorService.mapSensorAndGateway(sensor.id, this.current.gateway.gatewayDeviceId);
    this.alertService.alertSuccess('Sensor and Gateway Mapped Successfully!');
  }

  async fetchSensorsByEdid() {
    this.loadingService.show();
    try {
      const result = await this.sensorService.getSensorsByEdid(this.rfidReaderId.toString());
      if (!result.length) {
        this.alertService.alertError(`No Sensors for ${this.rfidReaderId}`);
      } else {
        const sensor = result.find(
          (x) => x.edid.toString() == this.rfidReaderId.toString() && x.sid == '1'
        );
        this.sensorToPut = sensor;
      }
    } catch (err) {
      this.alertService.handleError(err);
    }
    this.loadingService.hide();
  }

  async updateSensor(sensor: Sensor): Promise<Sensor> {
    let response: Sensor;

    this.loadingService.show();
    try {
      const payload = {
        conveyorId: this.conveyorId,
        aliasName: this.sensorToPut.sensorNickname,
        description: this.sensorToPut.description,
      };

      await this.sensorService.updateSensor(payload, this.sensorToPut.id);
      this.alertService.alertSuccess('Sensor Updated Successfully!');

      response = Object.assign(sensor, payload);
      this.store.dispatch(InstallationActions.updateSensor({ sensor: response }));
    } catch (err) {
      this.alertService.handleError(err);
    }
    this.loadingService.hide();

    return response;
  }

  updateSensorId() {
    this.store.dispatch(InstallationActions.updateNewSensorId({ sensorId: null }));
  }
}
