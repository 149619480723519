import { BaseState } from '../base.state';

import { User } from 'src/app/services/auth/auth.service';

export class UserState extends BaseState {
  currentUser?: User;
  error?: any;

  passwordResetInitiated: boolean;
  passwordResetCompleted: boolean;
}

export const initialState: UserState = {
  currentUser: null,
  error: null,
  passwordResetInitiated: false,
  passwordResetCompleted: false,
};
