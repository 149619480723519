<app-desktop-header [header]="survey?.customer?.customer_name">
  <div content>
    <div class="d-flex align-items-center gap-2">
      <a
        href="javascript:;"
        class="text-danger d-flex text-decoration-none me-2"
        (click)="onRemoveSurveyClick(survey)"
        data-testid="remove-survey-button">
        <i class="material-symbols-outlined">delete</i>
        {{ t.translate("_REMOVE") }}
      </a>
      <button
        class="btn btn-primary"
        [routerLink]="['/surveys', survey?.id, 'belts', 'create']"
        data-testid="desktop-add-belt-config-button">
        {{ t.translate("_ADD_BELT_CONFIG") }}
      </button>
      <app-meta-menu [menuItems]="menuItems"></app-meta-menu>
    </div>
  </div>
</app-desktop-header>

<div class="mx-lg-5 mt-lg-8">
  <div class="desktop">
    <div class="d-flex align-items-center my-3">
      <app-search-input
        class="col"
        [(searchText)]="searchText"
        (searchTextChange)="onSearchTextChange()"
        data-testid="desktop-belt-search">
      </app-search-input>

      <app-filter-button
        [hasActiveFilter]="hasActiveFilter"
        [filterCount]="filterCount"
        (filterButtonClick)="onFilterButtonClick()">
      </app-filter-button>
    </div>

    <div class="bg-white border rounded mb-5 overflow-x">
      <app-table
        sortColumn="date"
        sortDirection="DESC"
        data-testid="belt-table"
        [table]="beltTable"
        [isSticky]="true"
        (rowClick)="onItemClick($event)">
      </app-table>
    </div>
  </div>
</div>

<ng-template
  #imagePreviewModal
  let-modal>
  <div class="modal-header">
    <div
      class="modal-title"
      id="modal-basic-title">
      <h4 class="mb-0">{{ t.translate("_IMAGE") }} - {{ currentBelt.productReference }}</h4>
    </div>
    <div
      class="close pointer fs-3"
      aria-label="Close"
      (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </div>
  </div>
  <div class="modal-body">
    <div class="belt-image-preview d-flex justify-content-around">
      <img
        [src]="
          currentBelt?.attachmentUrl?.attachmentUrl ||
          currentBelt?.attachmentUrl ||
          currentBelt.image?.displayImage?.changingThisBreaksApplicationSecurity ||
          currentBelt.image?.displayImage
        "
        class="rounded" />
    </div>
  </div>
</ng-template>

<ng-template
  let-modal
  #importModal>
  <h4 class="modal-header">
    {{ t.translate("_IMPORT_BELTS_FROM_CSV") }}
  </h4>
  <div class="modal-body">
    <div class="file-container text-center">
      <div
        class="w-100 p-3 border rounded cursor-pointer"
        [class]="'border-' + getFileValidationColour()"
        (click)="fileUpload.click()">
        <div class="mb-3">
          {{ fileDetails?.file?.name || t.translate("_IMPORT_BELTS_FROM_CSV") }}
        </div>
        <div>
          <button class="btn btn-secondary">
            {{ t.translate("_SELECT_FILE") }}
          </button>
        </div>
      </div>
    </div>

    <input
      style="display: none"
      type="file"
      (change)="csvFileSelected($event)"
      accept=".csv"
      #fileUpload />
    <div
      class="mh-6 mt-3 overflow-auto small"
      *ngIf="fileDetails">
      <div
        [class]="'text-' + getFileValidationColour()"
        *ngIf="fileDetails.invalidBeltConfigs?.length">
        <div class="d-flex align-items-center gap-2">
          <i class="material-symbols-outlined">error</i>
          {{ fileDetails.validBeltConfigs.length }}/{{ fileDetails.csvBeltConfigs.length }}
          {{ t.translate("_ROWS_WILL_BE_IMPORTED") }}
        </div>

        <ul>
          <li *ngFor="let invalidBelt of fileDetails?.invalidBeltConfigs">
            {{ translateInvalidBeltConfigs(invalidBelt) }}
          </li>
        </ul>
      </div>
      <div
        [class]="'text-' + getFileValidationColour()"
        *ngIf="!fileDetails.invalidBeltConfigs?.length && !fileDetails.validBeltConfigs?.length">
        <div
          class="d-flex align-items-center gap-2"
          *ngIf="fileDetails.validBeltConfigs || fileDetails.invalidBeltConfigs">
          <i class="material-symbols-outlined">cancel</i>
          {{ translateCSVDialog() }}
        </div>
      </div>
      <div
        [class]="'text-' + getFileValidationColour()"
        *ngIf="!fileDetails.invalidBeltConfigs?.length && fileDetails.validBeltConfigs?.length">
        <div class="d-flex align-items-center gap-2">
          <i class="material-symbols-outlined">check_circle</i>
          {{ translateCSVDialog() }}
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="w-100 d-flex justify-content-between align-items-center">
      <button
        class="btn btn-outline-primary flex-center"
        (click)="downloadCsvTemplate()">
        <i class="material-symbols-outlined">download</i>
        {{ t.translate("_TEMPLATE") }}
      </button>

      <button
        type="button"
        class="btn btn-primary"
        (click)="importSurveyCsv()">
        {{ t.translate("_IMPORT") }}
        <span *ngIf="fileDetails?.invalidBeltConfigs?.length"> Anyway</span>
      </button>
    </div>
  </div>
</ng-template>
