import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component } from '@angular/core';

import { TranslateService } from './../../services/translate.service';
import { UtilService } from '../../utils/util.service';

@Component({
  templateUrl: 'delete-modal.component.html',
})
export class DeleteModalComponent {
  options: DeleteModalOptions;

  constructor(
		public bsModalRef: BsModalRef, 
		public t: TranslateService, 
		public u: UtilService
	) {}

  ngOnInit() {
    this.componentTranslations();
  }

  componentTranslations() {
    this.t.translate('_YES');
    this.t.translate('_NO');
  }

	onDelete() {
		this.options.delete();
		this.bsModalRef.hide();
	}

	close() {
		this.bsModalRef.hide();
	}
}

export class DeleteModalOptions {
	title: string;
	message: string;
	delete: () => void;
}
