import { cloneDeep } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { Customer } from 'src/app/models/customer.model';

import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { EncryptionService } from 'src/app/services/c-belt/encryption.service';
import { BluetoothLeService } from 'src/app/services/c-belt/bluetooth-le.service';

import { take } from 'rxjs';
import { Store } from '@ngrx/store';
import { ConfigurationState } from 'src/app/state/installation/installation.state';
import * as InstallationActions from 'src/app/state/installation/installation.actions';
import * as InstallationSelectors from 'src/app/state/installation/installation.selector';

@Component({
  selector: 'app-calibrate-conveyor-reader',
  templateUrl: './calibrate-conveyor-reader.component.html',
  styleUrls: ['./calibrate-conveyor-reader.component.scss'],
})
export class CalibrateConveyorReaderComponent implements OnInit, OnDestroy {
  devices = [];
  customer: Customer;
  current: ConfigurationState = null;

  name: string;
  address: string;
  baseRoute: string;
  companyId: string;
  serviceUuid: string;
  txPower: number;

  readerOn: boolean = false;
  servicesResolved: boolean = false;
  readerCalibrated: boolean = false;
  connectedToDevice: boolean = false;
  mtuSet: boolean = false;
  loading: boolean = false;

  txPowerValues: any = {
    F000: '0',
    F001: '1',
    F002: '2',
    F003: '3',
    F004: '4',
    F005: '5',
    F006: '6',
    F007: '7',
    F008: '8',
    F009: '9',
    F00A: '10',
    F00B: '11',
    F00C: '12',
    F00D: '13',
    F00E: '14',
    F00F: '15',
    F010: '16',
    F011: '17',
    F012: '18',
    F013: '19',
    F014: '20',
    F015: '21',
    F016: '22',
    F017: '23',
    F018: '24',
    F019: '25',
  };

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private encryptionService: EncryptionService,
    private bluetoothleService: BluetoothLeService
  ) {}

  ngOnInit() {
    this.route.url.subscribe((route) => {
      this.baseRoute = route[0].path;
    });
    this.route.params.subscribe((params) => {
      this.companyId = params['company_id'];
      this.subscribeToCurrentConfiguration();
    });
  }

  async ngOnDestroy(): Promise<void> {
    if (this.readerOn && this.connectedToDevice) {
      await this.bluetoothleService.disconnectAndClose(this.address);
    }
  }

  subscribeToCurrentConfiguration() {
    const select = InstallationSelectors.selectCurrentSummary();
    this.store
      .select(select)
      .pipe(take(1))
      .subscribe((current) => {
        this.current = cloneDeep(current);
        this.customer = this.current.company;
      });
  }

  async navToInstallingHome() {
    await this.bluetoothleService.disconnectAndClose(this.address);
    this.store.dispatch(InstallationActions.updateReaderCalibrated({ calibrated: true }));
    this.router.navigate([this.baseRoute, this.companyId]);
  }

  async toggleReaderOn() {
    try {
      const hasLocation = await this.bluetoothleService.hasLocationPermission();
      if (!hasLocation) await this.bluetoothleService.requestLocationPermission();
      const hasConnect = await this.bluetoothleService.hasConnectPermission();
      if (!hasConnect) await this.bluetoothleService.requestConnectPermission();
      const hasScan = await this.bluetoothleService.hasScanPermission();
      if (!hasScan) await this.bluetoothleService.requestScanPermission();
      const res = await this.bluetoothleService.initialize();
      if (!res) {
        this.alertService.alertError('Failed to Initialize');
      } else {
        this.readerOn = !this.readerOn;
      }
    } catch (err) {
      this.alertService.alertError(JSON.stringify(err));
    }
  }

  startScan() {
    this.bluetoothleService.startScan(
      (devices) => (this.devices = devices),
      (err) => {
        this.alertService.alertError('Failed to Start Scan');
      }
    );
  }

  stopScan() {
    this.bluetoothleService.stopScan();
  }

  async connectToDevice(name, address) {
    try {
      this.name = name;
      this.address = address;
      const connected = await this.bluetoothleService.connect(address);
      if (connected) {
        this.connectedToDevice = !this.connectedToDevice;
      }
    } catch (err) {
      this.alertService.alertError('Failed to Connect to Device');
    }
  }

  async fetchDeviceServices() {
    try {
      const deviceResult = await this.bluetoothleService.getDeviceServices(this.address);
      this.servicesResolved = !this.servicesResolved;
      const service = deviceResult.services.find(
        (x) => x.uuid == '594F4E47-4157-534E-2000-C7907585FC48'
      );
      const encryptedBytes = await this.bluetoothleService.read(this.address);
      let decryptedBytes = this.encryptionService.decryptValue(encryptedBytes);
      const decryptedHexStr = this.bytesToHexStr(decryptedBytes.slice(16, 33));
      this.txPower = Number(decryptedHexStr.slice(0, 2));
    } catch (err) {
      this.alertService.alertError('Failed to Fetch Device Services');
    }
  }

  getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  async adjustTxPower(direction: string) {
    try {
      if (direction == 'increase') {
        if (this.txPower == 25) return;
        this.txPower += 1;
      } else {
        if (this.txPower == 0) return;
        this.txPower -= 1;
      }

      if (!this.mtuSet) {
        const isSet = await this.bluetoothleService.updateMtu(this.address);
        if (isSet) {
          this.mtuSet = !this.mtuSet;
        } else {
          return;
        }
      }

      let identifier = this.getKeyByValue(this.txPowerValues, this.txPower.toString());

      const hexTimestamp = this.getHexTimestamp();
      let concatenated = identifier.toString().concat(hexTimestamp);
      concatenated = this.toThirtyTwoCharacters(concatenated);
      const bytes = this.hexStringToBytes(concatenated);
      const encryptedBytes = this.encryptionService.encryptValue(bytes);
      const writeResult = await this.bluetoothleService.write(this.address, encryptedBytes);
    } catch (err) {
      this.alertService.alertError('Failed to Adjust Tx Power');
    }
  }

  getHexTimestamp(): string {
    let timestamp = Math.trunc(Date.now() / 1000).toString(16);
    const arr = [];

    while (timestamp) {
      arr.push(timestamp.substring(0, 2));
      timestamp = timestamp.substring(2);
    }

    return arr.reverse().reduce((res, val) => res + val, '');
  }

  returnPowerValue(decryptedValue: string): number {
    let identifier = decryptedValue.slice(0, 4);
    let powerValue = this.txPowerValues[identifier];
    return Number(powerValue);
  }

  toThirtyTwoCharacters(concatenated: string): string {
    while (concatenated.length != 32) {
      concatenated = concatenated.concat('0');
    }
    return concatenated;
  }

  hexStringToBytes(hexStr: string): Uint8Array {
    let str = hexStr;
    const bytes = [];

    while (str) {
      bytes.push(parseInt(str.substring(0, 2), 16));
      str = str.substring(2);
    }

    return new Uint8Array(bytes);
  }

  bytesToHexStr(bytes: Uint8Array): string {
    let str = '';

    for (const byte of bytes) {
      str += byte.toString(16).padStart(2, '0').toUpperCase();
    }

    return str;
  }
}
