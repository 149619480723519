import { cloneDeep } from 'lodash';
import { Router } from '@angular/router';
import { Component, NgZone, OnInit } from '@angular/core';

import { CBelt } from 'src/app/models/cBelt.model';

import { BeltsService } from 'src/app/services/c-belt/belts.service';
import { StateService } from 'src/app/services/c-belt/state.service';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';

import { take } from 'rxjs';
import { Store } from '@ngrx/store';
import { ConfigurationState } from 'src/app/state/installation/installation.state';
import * as InstallationActions from 'src/app/state/installation/installation.actions';
import * as InstallationSelectors from 'src/app/state/installation/installation.selector';

@Component({
  selector: 'app-scanning-rfid',
  templateUrl: './scanning-rfid.component.html',
  styleUrls: ['./scanning-rfid.component.scss'],
})
export class ScanningRfidComponent implements OnInit {
  belt: CBelt = new CBelt();

  scanning: boolean = false;
  isRndUser: boolean = false;
  current: ConfigurationState = null;

  constructor(
    private store: Store,
    private router: Router,
    private ngZone: NgZone,
    private cBeltService: BeltsService,
    private alertService: AlertService,
    private stateService: StateService,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.subscribeToCurrentConfiguration();
  }

  subscribeToCurrentConfiguration() {
    const select = InstallationSelectors.selectCurrentSummary();
    this.store
      .select(select)
      .pipe(take(1))
      .subscribe((current) => {
        this.current = cloneDeep(current);
        this.isRndUser = this.current.isRndUser;
        this.belt = this.current.belt;
      });
  }

  startScanning() {
    this.toggleScanning();
    window['plugins'].rfidScannerPlugin.scan(
      (rfid) => this.ngZone.run(() => this.onScanFound(rfid)),
      (err) => this.ngZone.run(() => this.onRfidError(err))
    );
  }

  async onScanFound(rfid: string) {
    this.stopScanning();
    this.belt.rfidTagId = rfid;
    this.navToHome();
  }

  onRfidError(err) {
    this.scanning = false;
    this.alertService.alertError(JSON.stringify(err));
  }

  stopScanning() {
    window['plugins'].rfidScannerPlugin.stop(
      () => this.ngZone.run(() => this.onScanningStopped()),
      (err) => this.ngZone.run(() => this.onRfidError(err))
    );
  }

  private onScanningStopped() {
    this.toggleScanning();
  }

  private toggleScanning() {
    this.scanning = !this.scanning;
  }

  isValid() {
    if (this.isRndUser) {
      return (
        this.belt.width &&
        this.belt.length &&
        this.belt.rfidTagId &&
        this.belt.orderNumber &&
        this.belt.salesLocationCode
      );
    } else {
      return (
        this.belt.cpr &&
        this.belt.width &&
        this.belt.length &&
        this.belt.rfidTagId &&
        this.belt.orderNumber &&
        this.belt.salesLocationCode
      );
    }
  }

  async navToHome() {
    if (!this.isValid()) {
      this.alertService.alertError('Please fill in all fields');
      return;
    }

    const belt = await this.addBelt();
    if (!belt) return;

    this.belt.id = belt.id;
    this.alertService.alertSuccess('Belt Added Successfully!');
    this.store.dispatch(InstallationActions.updateBelt({ belt: this.belt }));
    this.store.dispatch(InstallationActions.setCurrentBelt({ belt: null }));
    this.store.dispatch(InstallationActions.updateIsRndUserStatus({ isRndUser: false }));
    this.stateService.subscribeToCurrentAndSave();
    this.router.navigate(['c-belt']);
  }

  async addBelt(): Promise<any> {
    this.loadingService.show();
    try {
      return await this.cBeltService.addBelt(this.belt);
    } catch (err) {
      if (err.status == 401) {
        this.alertService.alertError('Unauthorized');
      } else {
        this.alertService.handleError(err);
      }
    }
    this.loadingService.hide();
  }
}
