import { Action, createReducer, on } from '@ngrx/store';

import * as UserActions from './user.actions';
import { initialState, UserState } from './user.state';

const userReducer = createReducer(
  initialState,

  on(UserActions.setError, (state, { error }) => ({
    ...state,
    error,
  })),

  // on(UserActions.login, (state) => ({
  //   ...state,
  //   loading: true,
  //   error: null,
  // })),

  on(UserActions.loginSuccess, (state, { user }) => ({
    ...state,
    loading: false,
    loadedAt: new Date(),
    currentUser: user,
  })),

  on(UserActions.loginFailed, (state, { error }) => ({
    ...state,
    loading: false,
    currentUser: null,
    error,
  })),

  on(UserActions.logout, (state) => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  }),

  on(UserActions.logoutSuccess, (state) => {
    return {
      ...state,
      currentUser: null,
      loading: false,
    };
  })

  // on(UserActions.forgotPassword, (state) => ({
  //   ...state,
  //   loading: true,
  //   error: null,
  //   passwordResetInitiated: false,
  // })),

  // on(UserActions.forgotPasswordSuccess, (state) => ({
  //   ...state,
  //   loading: false,
  //   passwordResetInitiated: true,
  // })),

  // on(UserActions.forgotPasswordFailed, (state, { error }) => ({
  //   ...state,
  //   loading: false,
  //   error,
  // })),

  // on(UserActions.resetPassword, (state) => ({
  //   ...state,
  //   loading: true,
  //   error: null,
  //   passwordResetCompleted: false,
  // })),

  // on(UserActions.resetPasswordSuccess, (state) => ({
  //   ...state,
  //   loading: false,
  //   passwordResetCompleted: true,
  // })),

  // on(UserActions.resetPasswordFailed, (state, { error }) => ({
  //   ...state,
  //   loading: false,
  //   error,
  // })),
);

export function reducer(state: UserState | undefined, action: Action) {
  return userReducer(state, action);
}
