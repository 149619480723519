// angular
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

import { Survey } from '../../models/survey.model';
import { environment } from '../../../environments/environment';

// ngrx
import * as SurveyActions from '../survey/survey.actions';
import * as UserActions from '../user/user.actions';

@Injectable()
export class SurveyEffects {
    constructor(
        private actions$: Actions<any>,
        private httpClient: HttpClient,
    ) { }

  fetchSurveys$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SurveyActions.fetchSurveys, UserActions.loginSuccess),

      switchMap(() => {
        return this.httpClient.get<Survey[]>(`${environment.baseUrl}/surveys`).pipe(
          map((surveys) => {
            if (!surveys) {
              return SurveyActions.fetchSurveysFailed({
                error: { message: 'Error fetching surveys' },
              });
            }

            return SurveyActions.fetchSurveysSuccess({ surveys });
          }),
          catchError((error) => of(SurveyActions.fetchSurveysFailed({ error })))
        );
      })
    )
  );

  fetchSurveyCsv$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SurveyActions.fetchSurveyCsv),

      switchMap((action) => {
        return this.httpClient
          .get<any>(`${environment.baseUrl}/surveys/csv/${action.surveyId}`)
          .pipe(
            map((data) => {
              if (!data) {
                return SurveyActions.fetchSurveyCsvFailed({
                  error: { message: 'Something went wrong.' },
                });
              }

              return SurveyActions.fetchSurveyCsvSuccess({
                csv: data,
              });
            }),
            catchError((error) => of(SurveyActions.fetchSurveyCsvFailed({ error })))
          );
      })
    )
  );
}
